import { useAtomValue, useStore } from "jotai/react";
import type { Atom } from "jotai/vanilla";
import { useEffect, useRef } from "react";
import { suspend } from "../react/suspend.ts";

/**
 * Get the value of an atom when it is ready. Suspend otherwise.
 *
 * @param atom - The atom to get the value from.
 * @param getError - A function that returns the error if the value is in an error state, `null` or `undefined` otherwise.
 * @param isReady - A function that returns `true` when the value is ready.
 */
export function useSuspenseAtomValue<Value>(
    atom: Atom<Value>,
    isReady: (value: Value) => boolean,
    getError?: (value: Value) => unknown,
): Value {
    const store = useStore();
    const result = useAtomValue(atom, { store });

    const unsubscribeRef = useRef<() => void>();
    useEffect(() => {
        return () => {
            // Unsubscribe the promise listener (if it exists) when the component unmounts.
            unsubscribeRef.current?.();
        };
    }, [unsubscribeRef]);

    const error = getError?.(result);
    if (error != null) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw error;
    }

    if (isReady(result)) {
        return result;
    }

    /** Promise that will become resolved when the value is ready. */
    const readyPromise = new Promise<void>((resolve) => {
        // If we were already listening, kill the previous subscription. Can happen when eg. the atom changes.
        unsubscribeRef.current?.();

        unsubscribeRef.current = store.sub(atom, () => {
            const value = store.get(atom);
            if (isReady(value)) {
                resolve();
                unsubscribeRef.current!();
                unsubscribeRef.current = undefined;
            }
        });
    });

    suspend(readyPromise);
}
