import type { Key, ReactNode } from "react";
import { entries, filter, pipe } from "remeda";
import { ActionMenu } from "../components/ActionMenu.tsx";
import { WButton } from "../components/button/WButton.tsx";
import { ClipBoardTooltip } from "../components/ClipBoardTooltip.tsx";
import { WCheckbox } from "../components/forms/WCheckbox.tsx";
import { WSearchField } from "../components/forms/WSearchField.tsx";
import { CompactDateTime, type DateLike } from "../components/l10n/DateFormat.tsx";
import { WTable, WTableBody } from "../components/table/WTable.tsx";
import { todoAction } from "../dev/todoSubmit.ts";

/** Meta-properties about a field belonging to a {@link TItem}, which has the type {@link TValue}. */
export interface FieldConfig<TItem, TValue> {
    get: (obj: TItem) => TValue;
    render?: React.FC<{ value: TValue; item: TItem }>;

    title: ReactNode;
    editTitle?: ReactNode;
    copyable?: boolean;
    inTable?: boolean;
}

/** Convenience type for field definitions.
 *
 * @example const myFields = { ... } satisfies FieldsOf<MyType>;
 */
export type FieldsOf<TItem> = Record<string, FieldConfig<TItem, any>>;

/** Utility function to automatically infer types for a {@link FieldConfig} */
export function field<TItem, TValue>(
    title: ReactNode,
    get: (obj: TItem) => TValue,
    options?: Partial<FieldConfig<TItem, TValue>>,
): FieldConfig<TItem, TValue> {
    return { title, get, ...options };
}

/** "Map" over a field config, transforming the object it operates on. */
export function mapField<TNewItem, TItem, TValue>(
    { render, get, ...field }: FieldConfig<TItem, TValue>,
    mapFn: (obj: TNewItem) => TItem,
    options?: Partial<FieldConfig<TNewItem, TValue>>,
): FieldConfig<TNewItem, TValue> {
    return {
        ...field,
        get: (obj: TNewItem): TValue => get(mapFn(obj)),
        render: render ? ({ value, item }) => render({ value, item: mapFn(item) }) : undefined,
        ...options,
    };
}

export function NA() {
    return <span className="text-muted">N/A</span>;
}

export function Missing() {
    return <span className="text-muted">-</span>;
}

export function DateViewer({ value }: { value: DateLike }) {
    return <CompactDateTime date={value} />;
}

function FieldValue<TItem, TValue>({
    field,
    value,
    item,
}: {
    field: FieldConfig<TItem, TValue>;
    value: TValue;
    item: TItem;
}) {
    const { render: Render } = field;
    let rendered = Render ? (
        <Render value={value} item={item} />
    ) : value instanceof Date ? (
        <DateViewer value={value} />
    ) : typeof value === "string" || typeof value === "number" ? (
        <span className="whitespace-pre">{value}</span>
    ) : typeof value === "boolean" ? (
        <WCheckbox isSelected={value} />
    ) : value == null ? (
        <Missing />
    ) : (
        String(value)
    );
    if (field.copyable) {
        rendered = (
            <ClipBoardTooltip isHtml text={typeof value === "string" ? value : undefined}>
                {rendered}
            </ClipBoardTooltip>
        );
    }
    return rendered;
}

export function AdminTable<TItem>({
    items,
    fields,
    getId,
    actionButtons,
}: {
    items: TItem[];
    getId: (item: TItem) => Key;
    fields: Record<string, FieldConfig<TItem, any>>;
    actionButtons?: (item: TItem) => ReactNode;
}) {
    const fs = pipe(
        fields,
        entries(),
        filter(([, field]) => field.inTable !== false),
    );

    return (
        <WTable adminTable search={<WSearchField />}>
            <thead>
                <tr>
                    {fs.map(([id, field]) => (
                        <th key={id}>{field.title}</th>
                    ))}
                    <th className="text-right">
                        <FakeFilterMenu />
                    </th>
                </tr>
            </thead>
            <WTableBody>
                {items.map((item) => (
                    <tr key={getId(item)}>
                        {fs.map(([id, field]) => {
                            const value: unknown = field.get(item);
                            return (
                                <td key={id}>
                                    <FieldValue field={field} value={value} item={item} />
                                </td>
                            );
                        })}
                        <td className="text-right">
                            <div className="flex gap-0.5em justify-end">{actionButtons?.(item)}</div>
                        </td>
                    </tr>
                ))}
            </WTableBody>
        </WTable>
    );
}

export function FakeFilterMenu() {
    const items = [
        {
            id: "uuid",
            title: "UUID",
        },
    ];

    return (
        <ActionMenu header="Filter" onAction={todoAction} selectedKeys={[]} items={items}>
            <WButton
                color="muted"
                variant="ghost"
                size="xs"
                icon="jp-icon-toggles size-0.75rem"
                ariaLabel="Filter"
                action={undefined}
            />
        </ActionMenu>
    );
}
