import C from "./StandardSidebar.module.css";

import { cn } from "../../utils/classNames.ts";

export function SkeletonListItem() {
    return (
        <>
            <div className={C.StandardBlockIcon} />

            <div className={C.StandardBlockName}>
                <div className="w-65%" />
                <div className="w-40%" />
            </div>

            <div className={cn(C.StandardBlockInfo)}>
                <div className="w-70%" />
                <div className="w-100%" />
            </div>
        </>
    );
}
