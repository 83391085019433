import { atom } from "jotai/vanilla";
import invariant from "tiny-invariant";

export const injectedLocationsAtom = atom<string[]>([]);

export const locationsAtom = atom((get) => {
    const locations = get(injectedLocationsAtom);
    invariant(locations.length > 0, "Locations must be injected before use");
    return locations;
});
