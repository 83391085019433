import type { ReactNode } from "react";

export function scopeLabel(restricted: boolean | undefined): ReactNode {
    return restricted ? "Restricted" : "Global";
}

/** @param billingAccountName Set to `null` if billing account information is not available. `undefined` if Global scope. */
export function impersonationLabel(billingAccountName: string | null | undefined) {
    return billingAccountName ? (
        billingAccountName
    ) : billingAccountName === null ? (
        <i>Unknown account</i>
    ) : (
        <i>{scopeLabel(false)}</i>
    );
}
