import { Children, type ReactNode } from "react";
import { Link } from "react-aria-components";
import { useConfig } from "../../../config.ts";

export function ApiDocLink({ children }: { children?: ReactNode }) {
    const { apiDocUrl } = useConfig();
    return (
        <Link href={apiDocUrl} target="_blank">
            {Children.count(children) > 0 ? children : apiDocUrl}
        </Link>
    );
}
