import { useAtomValue } from "jotai/react";
import { atom } from "jotai/vanilla";
import { mergeQueries, type QMergedResult } from "../../utils/query/mergeQueries.ts";
import {
    allAssignedLbAndIpsQueryAtom,
    type IpAddressWithPrice,
    type LoadBalancerWithPrice,
} from "./ipAddress/joinAssignedQuery.ts";
import type * as vpcOps from "./vpc/apiOperations.ts";
import { allVpcsQueryAtom } from "./vpc/apiOperations.ts";

export type NetworkListItem = LoadBalancerWithPrice | IpAddressWithPrice | vpcOps.VpcWithType;
export type NetworkListData = NetworkListItem[];

export type NetworkListQResult = QMergedResult<NetworkListData>;

export const networkListQueryAtom = atom((get): NetworkListQResult => {
    const vpcs = get(allVpcsQueryAtom);
    const ips = get(allAssignedLbAndIpsQueryAtom);
    // return {
    //     ...mergeQueryStatuses({ vpcs, ips }),
    //     data: [...vpcs.data.values(), ...ips.data.values()],
    // };
    return mergeQueries({ vpcs, ips }, (maps) => maps.flatMap((m) => [...m.values()]));
});

export function useNetworkListQuery() {
    return useAtomValue(networkListQueryAtom);
}
