import type { NumberFieldProps, TextFieldProps } from "react-aria-components";
import type { ControllerFieldState, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form";
import type { FormFieldChildrenProps, FormFieldProps } from "./FormField.tsx";
import { requiredMessage } from "./requiredMessage.ts";

export function formPropsToAriaProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ field, fieldState }: { field: ControllerRenderProps<TFieldValues, TName>; fieldState: ControllerFieldState }) {
    return {
        onChange: field.onChange,
        onBlur: field.onBlur,
        value: field.value,
        isDisabled: field.disabled,

        isInvalid: fieldState.invalid,
        errorMessage: fieldState.error?.message,

        validationBehavior: "aria",
    } satisfies (TextFieldProps | NumberFieldProps) & Pick<FormFieldChildrenProps, "errorMessage">;
}

export function formPropsToFormFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ fieldState }: { field: ControllerRenderProps<TFieldValues, TName>; fieldState: ControllerFieldState }) {
    return {
        errorMessage: fieldState.error?.message,
    } satisfies FormFieldProps;
}

export function ariaRules({ isRequired }: { isRequired?: boolean }) {
    return {
        required: isRequired ? requiredMessage : false,
    };
}

export function controllerProps(props: { isRequired?: boolean; isDisabled?: boolean }) {
    const { isDisabled } = props;
    return { isDisabled, rules: ariaRules(props) };
}
