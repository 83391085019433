import C from "./MainLayout.module.css";

import { useAtom, useAtomValue, useSetAtom } from "jotai/react";
import { memo, useTransition, type ReactNode } from "react";
import type { BaseProps } from "../../utils/baseProps";
import { cn } from "../../utils/classNames";
import { LayoutContent } from "./LayoutContent.tsx";
import { showOnlyContentAtom } from "./MainLayout.store.ts";
import { SideNav } from "./SideNav";
import { TopNav } from "./TopNav";
import { Sidebar } from "./sidebar/Sidebar";
import { SetSectionFromRouter } from "./sidebar/Sidebar.routing";
import { closeSidebarAction, currentSectionAtom, sectionsAtom } from "./sidebar/Sidebar.store";

const MainSideNav = memo(function MainSideNav(props: BaseProps) {
    const [sectionId, setSectionId] = useAtom(currentSectionAtom);
    const closeSidebar = useSetAtom(closeSidebarAction);
    const sidebarSections = useAtomValue(sectionsAtom);
    // TODO: display a loading indicator while transitioning?
    const [_inTransition, startTransition] = useTransition();

    return (
        <SideNav
            items={sidebarSections}
            currentItemId={sectionId}
            onPressItem={(section) => {
                // Start a transition - this means any Suspense fallbacks will not be shown (e.g. due to lazy loading in the side bar)
                startTransition(() => {
                    if (section.id === sectionId) {
                        console.debug("Clicked on the same section");
                        closeSidebar();
                    } else {
                        setSectionId(section.id);
                    }
                });
            }}
            {...props}
        />
    );
});

export function MainLayout({ children }: { children?: ReactNode }) {
    const showOnlyContent = useAtomValue(showOnlyContentAtom);
    if (showOnlyContent) {
        return children;
    }

    return (
        <div className="size-full flex flex-col">
            <SetSectionFromRouter />
            <TopNav />
            <div className={cn(C.mainArea)}>
                <MainSideNav className={cn(C.ScrollableNav)} />
                <Sidebar className={cn(C.Sidebar)} />
                <LayoutContent className={cn("flex-grow", C.Scrollable, C.strict)}>{children}</LayoutContent>
            </div>
        </div>
    );
}
