import type { QueryClient } from "@tanstack/query-core";
import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { ApiToken, ApiTokenCreateBody, ApiTokenModifyBody } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries } from "@warrenio/utils/collections/maps";
import { urlEncodedBody } from "../../../utils/fetchClient.ts";
import { optimisticPromise } from "../../../utils/query/optimisticPromise.ts";
import { produceQueryData } from "../../../utils/query/produceQueryData.ts";
import { atomFromStandardQueryOptions } from "../../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../../utils/query/runMutation.ts";
import { getResponseData, type ApiClient } from "../../api/apiClient.ts";
import { getResourceIconClass } from "../../api/resourceTypes.tsx";
import { raiseRequestToast, type Toast } from "../../notifications/toast.tsx";
import { tokenLink } from "../links.ts";

//#region basic Types/Contracts

export type Response = Map<ApiToken["id"], ApiTokenWithType>;

export const emptyResponse: Response = new Map();

export interface ApiTokenWithType extends ApiToken {
    $type: "api_token";
}

const queryKey: QueryKey = ["/user-resource/token/list"];

//#endregion

//#region Queries
export function apiTokenListQuery(apiClient: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            const response = getResponseData(await apiClient.GET("/user-resource/token/list", { signal }));

            return mapFromEntries(response, (o) => [o.id, apiTokenFromData(o)]);
        },
    });
}

function apiTokenFromData(data: ApiToken): ApiTokenWithType {
    return { ...data, $type: "api_token" };
}

export const apiTokenListQueryAtom = atomFromStandardQueryOptions(apiTokenListQuery);

//#endregion

//#region Mutations

const toastOptions: Partial<Toast> = { icon: getResourceIconClass("api_token") };

export function createApiTokenMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["api_token/create"],
        async mutationFn({ body }: { body: ApiTokenCreateBody }) {
            return getResponseData(
                await api.POST("/user-resource/token", {
                    ...urlEncodedBody,
                    body,
                }),
            );
        },
        onSuccess: (response, _mutation) => {
            produceQueryData(queryClient, queryKey, emptyResponse, (draft) => {
                draft.set(response.id, apiTokenFromData(response));
            });
        },
        onSettled(res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Api Token created",
                error: "Error creating Api Token",
                successType: "success",
                viewLink: res ? tokenLink(res) : undefined,
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey }));
        },
    });
}

export function modifyApiTokenMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["api_token/modify"],
        async mutationFn({ body }: { body: ApiTokenModifyBody }) {
            return getResponseData(
                await api.PATCH("/user-resource/token", {
                    ...urlEncodedBody,
                    body,
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Api Token modified",
                error: "Error modifying Api Token",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function deleteApiTokenMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["api_token/delete"],
        async mutationFn({ id }: { id: number }) {
            return getResponseData(
                await api.DELETE("/user-resource/token", {
                    ...urlEncodedBody,
                    body: { token_id: id },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Api Token deleted",
                error: "Error deleting Api Token",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
        onSuccess(_res, _mutation) {},
    });
}

//#endregion
