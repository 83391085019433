import type { QueryClient } from "@tanstack/query-core";
import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type { Vpc, VpcCreateBody, VpcUpdateBody } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { jsonEncodedBody, urlEncodedBody } from "../../../utils/fetchClient.ts";
import { optimisticPromise } from "../../../utils/query/optimisticPromise.ts";
import { produceQueryData } from "../../../utils/query/produceQueryData.ts";
import { atomFromStandardQueryOptions } from "../../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../../utils/query/runMutation.ts";
import { type ApiClient, getResponseData } from "../../api/apiClient.ts";
import { getResourceIconClass } from "../../api/resourceTypes.tsx";
import { atomAllLocationsQuery } from "../../location/atomAllLocationsQuery.ts";
import { raiseRequestToast, type Toast } from "../../notifications/toast.tsx";
import { vpcLink } from "../links.ts";

//#region Query

export interface Params {
    location: string;
}

export interface VpcWithType extends Vpc, Params {
    $type: "vpc";
}

export type Response = Map<Vpc["uuid"], VpcWithType>;

const emptyResponse: Response = new Map();

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["vpc/list"] : ["vpc/list", params.location];
}

function vpcFromData(data: Vpc, location: string): VpcWithType {
    return { ...data, $type: "vpc", location };
}

export function getSingleQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            const response = getResponseData(
                await apiClient.GET("/{location}/network/networks", {
                    signal,
                    params: { path: { location } },
                }),
            );
            return mapFromEntries(response, (vpc) => [vpc.uuid, vpcFromData(vpc, location)]);
        },
    });
}

export const vpcQueryAtom = atomFamily((location: string) =>
    atomFromStandardQueryOptions(getSingleQuery, { location }),
);

export const allVpcsQueryAtom = atomAllLocationsQuery(vpcQueryAtom, (results) => mergeMaps(results));

//#endregion

//#region Mutations

const toastOptions: Partial<Toast> = { icon: getResourceIconClass("vpc") };

export function createVpcNetworkMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["vpc/create"],
        async mutationFn({ body, location }: { body: VpcCreateBody; location: string }) {
            const result = getResponseData(
                await api.POST("/{location}/network/network", {
                    ...urlEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
            return vpcFromData(result, location);
        },
        onSuccess: (response, { location }) => {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onSettled(res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "VPC Network created",
                error: "Error creating VPC Network",
                successType: "success",
                viewLink: res ? vpcLink(res) : undefined,
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
        },
    });
}

export function modifyVpcNetworkMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["vpc/update"],
        async mutationFn({ body, location, uuid }: { body: VpcUpdateBody; location: string; uuid: string }) {
            const result = getResponseData(
                await api.PATCH("/{location}/network/network/{uuid}", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location, uuid } },
                }),
            );
            // TODO: does this query really return nothing?
            // return vpcFromData(result, location);
            return result;
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "VPC Network updated",
                error: "Error updating VPC Network",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
        onSuccess(_res, _mutation) {},
    });
}

export function deleteVpcNetworkMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["vpc/delete"],
        async mutationFn({ location, uuid }: { location: string; uuid: string }) {
            return getResponseData(
                await api.DELETE("/{location}/network/network/{uuid}", {
                    ...jsonEncodedBody,
                    params: { path: { location, uuid } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "VPC Network deleted",
                error: "Error deleting VPC Network",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
        onSuccess(_res, _mutation) {},
    });
}

export function setDefaultVpcNetworkMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["vpc/set_default"],
        async mutationFn({ location, uuid }: { location: string; uuid: string }) {
            const result = getResponseData(
                await api.PUT("/{location}/network/network/{uuid}/default", {
                    ...jsonEncodedBody,
                    params: { path: { location, uuid } },
                }),
            );
            return vpcFromData(result, location);
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "VPC Network set as default",
                error: "Error setting VPC Network as default",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
        onSuccess(_res, _mutation) {},
    });
}

//#endregion
