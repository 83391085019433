import type { ReactNode } from "react";
import { WTooltip } from "../WTooltip.tsx";
import { WButton, type WButtonProps } from "./WButton.tsx";

export interface WToolButtonProps extends Omit<WButtonProps, "ariaLabel" | "label" | "children"> {
    label: ReactNode;
    /** Optional when {@link label} is a string */
    textLabel?: string;
    /** Compact, icon-only view for use in table cells */
    inTable?: boolean;
}

/**
 * Button to use in toolbars or table action columns.
 *
 * NB: The {@link WButton} `variant` and `size` props are overridden when `inTable` is `true`.
 */
export function WToolButton({ inTable, label, textLabel, ...props }: WToolButtonProps) {
    // TODO: Integrate the tooltip feature into `WButton` itself?
    if (label === textLabel) {
        console.warn("WToolButton: No need to specify `textLabel` when `label` is a string: %o", label);
    }

    if (typeof label === "string") {
        textLabel ??= label;
    }

    const { icon } = props;
    if (inTable) {
        // Show text label if there is no icon
        const showTextLabel = icon == null;
        const button = (
            <WButton color="primary" ariaLabel={textLabel} {...props} variant="ghost" size="xs">
                {showTextLabel ? textLabel : undefined}
            </WButton>
        );

        return showTextLabel ? button : <WTooltip text={textLabel}>{button}</WTooltip>;
    } else {
        return (
            <WButton color="primary" variant="ghost" size="bar" {...props}>
                {label}
            </WButton>
        );
    }
}

/** Button for triggering a modal.
 * @see {@link WToolButton}
 */
export function WModalButton(props: Omit<WToolButtonProps, "action">) {
    return <WToolButton action={undefined} {...props} />;
}

export interface WTooltipButtonProps extends WButtonProps {
    title: string;
}

export function WTooltipButton({ title, ...props }: WTooltipButtonProps) {
    return (
        <WTooltip text={title}>
            <WButton ariaLabel={title} {...props} />
        </WTooltip>
    );
}
