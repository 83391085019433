import type { ComponentPropsWithoutRef } from "react";
import { useBlobCache } from "../../utils/BlobCache.ts";
import { mcn } from "../../utils/baseProps.ts";

export function DataIcon({
    data,
    mimeType,
    mode = "mask",
    ...props
}: {
    /** base64 encoded image */
    data: string;
    mimeType?: string;
    mode?: "background" | "img" | "mask";
} & Omit<ComponentPropsWithoutRef<"span" | "img">, "style">) {
    const extraCn =
        mode === "background"
            ? "bg-contain bg-no-repeat inline-block bg-center"
            : mode === "mask"
              ? "mask-icon inline-block"
              : "inline-block";

    props = mcn(extraCn, props);

    const url = useBlobCache(data, mimeType);

    return mode === "img" ? (
        <img {...props} src={url} />
    ) : mode === "mask" ? (
        // TODO: check for `-webkit-mask-image` support
        <span {...props} style={url ? { maskImage: `url('${url}')` } : undefined} />
    ) : (
        <span {...props} style={url ? { backgroundImage: `url('${url}')` } : undefined} />
    );
}
