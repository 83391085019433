import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { makeOnce } from "@warrenio/utils/makeOnce";
import { useAtomValue } from "jotai/react";
import { useHydrateAtoms } from "jotai/utils";
import { memo } from "react";
import { I18nProvider } from "react-aria";
import { siteLocaleAtom } from "../../config.ts";
import { useQueryHotReload } from "../../mock-api/useQueryHotReload.ts";
import { routerAtom } from "../../router.store.ts";
import { createStandardRouter } from "../../router.ts";
import { isBuiltRelease } from "../../utils/environment.ts";
import { useSuspenseQueryStaleOnErrorAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { injectedLocationsAtom } from "../api/locations.store.ts";
import { queryClientAtom } from "../api/queryClient";
import { queryAtom } from "../location/query.ts";
import { WChatProvider } from "../support/WChatProvider.tsx";
import QueryPrefetch from "./QueryPrefetch.tsx";

const router = makeOnce(createStandardRouter);

/** Root component in production */
const MainRoot = memo(function MainRoot() {
    const siteLocale = useAtomValue(siteLocaleAtom);
    const queryClient = useAtomValue(queryClientAtom);

    // XXX(WRN-318): Pre-load the locations and initialize the locations atom
    const locations = useSuspenseQueryStaleOnErrorAtom(queryAtom);
    const queryLocations = [...locations.keys()];

    useHydrateAtoms([
        [routerAtom, router()],
        [injectedLocationsAtom, queryLocations],
    ]);

    useQueryHotReload(queryClient);

    return (
        <QueryClientProvider client={queryClient}>
            <I18nProvider locale={siteLocale}>
                {
                    // Only prefetch in release (to prevent console spam in development)
                    // NB: This should be imported using `React.lazy` since otherwise it can cause cyclic dependencies, but MainRoot is already lazy-loaded
                    isBuiltRelease && <QueryPrefetch />
                }
                <WChatProvider>
                    <RouterProvider router={router()} />
                </WChatProvider>
            </I18nProvider>
        </QueryClientProvider>
    );
});

export default MainRoot;
