import { DataIcon } from "../../../components/icon/DataIcon.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import type { BaseProps } from "../../../utils/baseProps.ts";
import { mcn } from "../../../utils/baseProps.ts";
import { useMetalOs } from "./useMetalOs.ts";

export function OsIcon({ os_id, ...props }: { os_id: string | null | undefined } & BaseProps) {
    const { icon } = useMetalOs(os_id);

    return icon ? <DataIcon data={icon} mode="mask" {...props} /> : <MaskIcon {...mcn("jp-host-icon", props)} />;
}
