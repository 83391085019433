import type { RefetchOptions } from "@tanstack/query-core";
import { filterNulls } from "@warrenio/utils/collections/filterNulls";
import { unique, uniqueBy } from "remeda";
import { getErrorId } from "./errorId.ts";
import type { QStatus } from "./mergeQueries.ts";

/** An error that happened during a query with extra information. */
export interface QError {
    /** A unique ID useful as eg. a React key */
    id: number;
    error: Error;
    refetch: (options?: RefetchOptions) => Promise<unknown>;
}

/** Utility function to get all errors from a {@link QStatus} */
export function getStatusErrors(status: QStatus): QError[] {
    const { error } = status;
    if (error instanceof MultiError) {
        return error.queryErrors;
    }

    const singleError = queryErrorFromStatus(status);
    return singleError ? [singleError] : [];
}

/** Convert a {@link QStatus} to a {@link QError} */
function queryErrorFromStatus(status: QStatus): QError | undefined {
    const { error, refetch } = status;
    if (!error) {
        return undefined;
    }
    const id = getErrorId(error);
    return { id, error, refetch };
}

/**
 * Remove duplicate errors.
 *
 * Necessary because when merging errors from already merged queries, we need to keep track of which errors are shared
 * between them. (since multiple queries can fail due to the same underlying error - the queries can form a DAG, not
 * just a tree)
 *
 * We assume that single queries will always have unique errors - otherwise `refetch()` would not work properly, since
 * then multiple different queries would need to be re-fetched for the same error.
 */
function removeErrorDuplicates(queryErrors: readonly QError[]): QError[] {
    return uniqueBy(queryErrors, (e) => e.id);
}

function getErrorMessage(queryErrors: QError[]) {
    const messages = unique(queryErrors.map((e) => e.error.message));
    if (messages.length === 1) {
        return messages[0];
    }
    return `Multiple errors:\n${messages.join("\n")}`;
}

/** Merges multiple query errors into a single one */
export class MultiError extends Error {
    name = "MultiError";

    /** A list of errors. Can be individually re-fetched using {@link QError.refetch}. */
    public readonly queryErrors: QError[];

    protected constructor(queryErrors: QError[]) {
        queryErrors = removeErrorDuplicates(queryErrors);

        // Add a cause link so the stack trace of at least one of the errors is preserved (in the console)
        const cause = queryErrors[0].error;

        super(getErrorMessage(queryErrors), { cause });

        this.queryErrors = queryErrors;
    }

    get errors(): Error[] {
        return this.queryErrors.map((e) => e.error);
    }

    static fromErrors(errors: QError[]) {
        if (errors.length === 0) {
            return undefined;
        }

        // Lift the inner errors of MultiErrors to the top level
        // (so MultiErrors will never be nested and all errors can be obtained directly from a single one)
        const flatErrors = errors.flatMap((e) => (e.error instanceof MultiError ? e.error.queryErrors : [e]));
        return new MultiError(flatErrors);
    }

    static fromStatuses(statuses: QStatus[]) {
        return MultiError.fromErrors(filterNulls(statuses.map((value) => queryErrorFromStatus(value))));
    }
}
