import type { RefObject } from "react";
import { useLayoutEffect, useRef } from "react";

interface LayoutSizeObserverProps<T extends HTMLElement> {
    /** The element whose size to measure */
    ref: RefObject<T>;
    /** Second argument is the element itself (for direct DOM mutations). */
    onResize: (rect: DOMRectReadOnly, element: T) => void;
    /**
     * Options to pass to the ResizeObserver.
     *
     * NB: ignored as a dependency, can not change.
     */
    options?: ResizeObserverOptions;
}

/**
 * Hook that calls {@link onResize} with the size of the element in {@link ref}.
 * Called on first paint and every resize.
 *
 * For maximum performance, do not call `setState` hooks in `onResize`, but instead mutate the DOM directly
 * (if possible - an attribute can not be simultaneously be set manually and by React).
 */
export function useLayoutSizeObserver<T extends HTMLElement>({ ref, onResize, options }: LayoutSizeObserverProps<T>) {
    const onResizeRef = useRef(onResize);
    onResizeRef.current = onResize;

    useLayoutEffect(() => {
        // import.meta.env.DEV && performance.mark("useLayoutSizeObserver: connected");

        const element = ref.current;
        if (!element) {
            console.error("useLayoutSizeObserver: Ref is not set");
            return;
        }
        const observer = new ResizeObserver((entries) => {
            const { contentRect } = entries[entries.length - 1];
            onResizeRef.current(contentRect, element);
        });
        // NOTE: ResizeObserver will always fire on first paint, so we don't need to call onResize manually
        observer.observe(element, options);
        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}
