import type { ReactNode } from "react";
import { LoadingSuspense } from "../../components/loading/Loading";
import type { BaseProps } from "../../utils/baseProps";
import { useBreakpointSizeAttribute } from "./breakPoints.ts";

export function LayoutContent({ children, ...props }: { children?: ReactNode } & BaseProps) {
    const ref = useBreakpointSizeAttribute();

    return (
        <main ref={ref} {...props}>
            <LoadingSuspense>{children}</LoadingSuspense>
        </main>
    );
}
