import C from "./Badge.module.css";

import type { ReactNode } from "react";
import { cn } from "../utils/classNames.ts";
import { vi, type VariantInfoProps } from "../utils/variants.ts";

// eslint-disable-next-line react-refresh/only-export-components
export const vars = vi({
    variants: {
        color: ["primary", "success", "error", "warning", "info", "default", "text", "white"],
        noDot: [true, false, undefined],
        inHeader: [true, false, undefined],
        inList: [true, false, undefined],
    },
    defaults: {
        color: "default",
        noDot: false,
        iconOnly: false,
        inHeader: false,
        inList: false,
        reverse: false,
    },
});

export interface BadgeProps extends VariantInfoProps<typeof vars> {
    children: ReactNode;
    color?: "primary" | "success" | "error" | "warning" | "info" | "default" | "text" | "white";
    noDot?: boolean;
    iconOnly?: boolean;
    inHeader?: boolean;
    inList?: boolean;
    reverse?: boolean;
}

export function Badge({
    children,
    color = vars.defaults.color,
    noDot = vars.defaults.noDot,
    iconOnly = vars.defaults.iconOnly,
    inHeader = vars.defaults.inHeader,
    inList = vars.defaults.inList,
    reverse = vars.defaults.reverse,
}: BadgeProps) {
    const variantCns = cn(
        C[color],
        !noDot && C.dot,
        iconOnly && C.Icon,
        inHeader && C.inHeader,
        inList && C.inList,
        reverse && C.Reverse,
    );

    return <div className={cn(C.Badge, variantCns)}>{children}</div>;
}
