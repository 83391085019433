import { mapOnValues } from "@warrenio/utils/collections/maps";
import { atom } from "jotai/vanilla";
import { mergeLoadedQueries, type QMergedResult } from "../../utils/query/mergeQueries.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { pricesAtom } from "../pricing/query.ts";
import { getBucketPrice } from "../pricing/resourcePricing.ts";
import { objectStorageListQuery, type StorageWithPrice } from "./objectStorage/apiOperations.ts";

export type StorageListItem = StorageWithPrice;
export type StorageListData = StorageListItem[];

export type StorageListQResult = QMergedResult<StorageListData>;

export const storageListQueryAtom = atomFromStandardQueryOptions(objectStorageListQuery);

export const storageListQueryWithPricesAtom = atom((get): QMergedResult<Map<string, StorageWithPrice> | undefined> => {
    return mergeLoadedQueries(
        {
            buckets: get(storageListQueryAtom),
            prices: get(pricesAtom),
        },
        ({ buckets, prices }) =>
            mapOnValues(
                buckets,
                (bucket): StorageWithPrice => ({
                    ...bucket,
                    price: getBucketPrice(prices, bucket),
                }),
            ),
    );
});
