import "react-toastify/dist/ReactToastify.css";
import "./Toastify.css";

import { ToastContainer } from "react-toastify";
import type { ActionEventFn } from "../../components/Action.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import type { BaseProps } from "../../utils/baseProps";
import { unsetPropertyCheckProxy } from "../../utils/debug/unsetPropertyCheckProxy.ts";

/**
 * Docs:
 *  - https://fkhadra.github.io/react-toastify/api/toast-container
 *  - https://fkhadra.github.io/react-toastify/how-to-style
 */

const CloseButton = ({ closeToast }: { closeToast: ActionEventFn }) => (
    <WButton variant="ghost" size="xs" icon="jp-icon-close" ariaLabel="Close" action={closeToast} />
);

export function Toaster(props: BaseProps) {
    const fakeMouseEvent = unsetPropertyCheckProxy({}) as React.MouseEvent<HTMLElement>;
    return (
        <ToastContainer
            position="top-right"
            limit={5}
            icon={false}
            newestOnTop={false}
            toastClassName={undefined} // TODO: add custom toast class
            bodyClassName={undefined}
            closeButton={({ closeToast }) => <CloseButton closeToast={() => closeToast(fakeMouseEvent)} />} // TODO: add custom close button
            {...props}
        />
    );
}
