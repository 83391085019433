import type { QueryClient, QueryKey, QueryObserverResult } from "@tanstack/query-core";
import { QueryObserver, type UseQueryOptions, type UseSuspenseQueryOptions } from "@tanstack/react-query";
import { atom, type Atom } from "jotai/vanilla";
import { apiClientAtom } from "../../modules/api/apiClient.store.ts";
import type { ApiClient } from "../../modules/api/apiClient.ts";
import { queryClientAtom } from "../../modules/api/queryClient.ts";

const debugLogs = false;

export function atomFromQueryOptions<TData, TError, TQueryFnData, TQueryKey extends QueryKey>(
    options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    queryClient: QueryClient,
): Atom<QueryObserverResult<TData, TError>> {
    let _debugId;
    function _dbg(msg: string, ...args: unknown[]) {
        if (!debugLogs) {
            return;
        }
        _debugId ??= JSON.stringify(defaultedOptions.queryKey);
        console.debug(`${_debugId}: ${msg}`, ...args);
    }

    // Enable optimistic results
    const defaultedOptions = queryClient.defaultQueryOptions(options);
    defaultedOptions._optimisticResults = "optimistic";

    const observer = new QueryObserver(queryClient, defaultedOptions);

    const initialResult = observer.getOptimisticResult(defaultedOptions);
    _dbg("Creating result atom with result: %o", debugFields(initialResult));

    const observerStateAtom = atom(initialResult);
    observerStateAtom.onMount = (set) => {
        _dbg("Subscribing to query observer");
        const unsubscribe = observer.subscribe((state) => {
            _dbg("Query observer state change: %o", debugFields(state));
            set(state);
        });
        return () => {
            _dbg("Unsubscribing from query observer");
            unsubscribe();
        };
    };

    return atom((get) => {
        // Trigger mount, ignore subscribed result (for some reason)
        const observerResult = get(observerStateAtom);

        const result = observer.getOptimisticResult(defaultedOptions);
        _dbg("Returning result:\nobserver: %o\noptimistic: %o)", debugFields(observerResult), debugFields(result));
        // Track only when accessed fields change
        return !defaultedOptions.notifyOnChangeProps ? observer.trackResult(result) : result;
    });
}

export function atomFromStandardQueryOptions<
    TData,
    TError,
    TQueryFnData,
    TQueryKey extends QueryKey,
    TArgs extends unknown[],
>(
    getQueryOptions: (
        apiClient: ApiClient,
        ...args: TArgs
    ) => UseSuspenseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    ...args: TArgs
): Atom<QueryObserverResult<TData, TError>> {
    const queryAtom = atom((get) => {
        const apiClient = get(apiClientAtom);
        const options = getQueryOptions(apiClient, ...args);

        const queryClient = get(queryClientAtom);
        return atomFromQueryOptions(options, queryClient);
    });
    return atom((get) => get(get(queryAtom)));
}

function debugFields<TData, TError>(result: QueryObserverResult<TData, TError>) {
    if (!debugLogs) {
        return;
    }

    const { status, data, error } = result;
    return { status, data, error };
}
