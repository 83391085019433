import { memo } from "react";
import { Skeleton, type SkeletonProps } from "./Skeleton";

interface SkeletonFixedProps extends Omit<SkeletonProps, "words"> {
    /** Width in ems */
    ems: number;
    /** Display as inline (instead of a block). Automatically set if lines is 1. */
    inline?: boolean;
}

/** A {@link Skeleton} that will always be the specified amount of ems wide. Automatically determines the number of words. */

export const SkeletonFixed = memo(function SkeletonFixed({
    ems,
    lines = 1,
    inline = lines === 1,
    ...props
}: SkeletonFixedProps) {
    return (
        <div className={inline ? "inline-block" : ""} style={{ width: `${ems}em` }}>
            <Skeleton lines={lines} words={Math.ceil(ems / 4)} {...props} />
        </div>
    );
});
