import type { QueryClient, QueryKey } from "@tanstack/query-core";
import {
    useMutation,
    useQuery,
    useQueryClient,
    useSuspenseQuery,
    type UseMutationOptions,
    type UseSuspenseQueryOptions,
} from "@tanstack/react-query";
import { useApiClient } from "./apiClient.store.ts";
import type { ApiClient } from "./apiClient.ts";

export function useStandardMutation<TArgs extends unknown[], TData, TError, TVariables, TContext>(
    fn: (api: ApiClient, query: QueryClient, ...args: TArgs) => UseMutationOptions<TData, TError, TVariables, TContext>,
    ...args: TArgs
) {
    const apiClient = useApiClient();
    const queryClient = useQueryClient();
    return useMutation(fn(apiClient, queryClient, ...args));
}

export function useStandardSuspenseQuery<
    TArgs extends unknown[],
    TData,
    TError,
    TVariables,
    TQueryKey extends QueryKey,
>(
    fn: (api: ApiClient, ...args: TArgs) => UseSuspenseQueryOptions<TData, TError, TVariables, TQueryKey>,
    ...args: TArgs
) {
    const apiClient = useApiClient();
    return useSuspenseQuery(fn(apiClient, ...args));
}

export function useStandardQuery<TArgs extends unknown[], TData, TError, TVariables, TQueryKey extends QueryKey>(
    fn: (api: ApiClient, ...args: TArgs) => UseSuspenseQueryOptions<TData, TError, TVariables, TQueryKey>,
    ...args: TArgs
) {
    const apiClient = useApiClient();
    return useQuery(fn(apiClient, ...args));
}
