import TC from "./Table.module.css";

import type { ComponentProps, ReactNode } from "react";
import React, { Children, useState } from "react";
import { mcn } from "../../utils/baseProps.ts";
import { cn } from "../../utils/classNames.ts";
import { WButton } from "../button/WButton.tsx";
import { emptyRecordsText } from "../empty.tsx";

interface WTableProps extends ComponentProps<"table"> {
    children: ReactNode;
    afterTable?: ReactNode;
    adminTable?: boolean;
    search?: ReactNode;
}

interface WSmartTableProps<T> extends Omit<WTableProps, "children"> {
    items: T[];
    children: (item: T, index: number) => ReactNode;

    head?: ReactNode;
    hasViewAllButton?: boolean;
}

/** Responsive `<table>` wrapper. */
export function WTable({ children, afterTable, adminTable, search, ...props }: WTableProps) {
    return (
        <>
            {search && <div className="p-2">{search}</div>}

            <div className={TC.tableContainer}>
                <table {...mcn(cn(TC.data, adminTable && TC.admin), props)}>{children}</table>
            </div>

            {Children.count(afterTable) > 0 && <div className="pt-3 flex gap-0.5rem">{afterTable}</div>}
        </>
    );
}

/** `<tbody>` wrapper. Automatically displays an {@link EmptyRow} if there are no children. */
export function WTableBody({ children }: { children: ReactNode[] | ReactNode }) {
    return <tbody>{React.Children.count(children) > 0 ? children : <EmptyRow />}</tbody>;
}

export function EmptyRow({ colSpan = 99, children }: { colSpan?: number; children?: ReactNode }) {
    return (
        <tr className={TC.empty}>
            <td colSpan={colSpan}>{children ?? emptyRecordsText}</td>
        </tr>
    );
}

export function WSmartTable<T>({
    children,
    items,

    head,
    afterTable,
    hasViewAllButton,
    ...tableProps
}: WSmartTableProps<T>) {
    const [viewAll, setViewAll] = useState<boolean>(!hasViewAllButton);

    function toggleViewAll() {
        setViewAll(!viewAll);
    }

    const limit = 10;

    const shownItems = viewAll ? items : items.slice(0, limit);

    const buttonVisible = hasViewAllButton && items.length > limit;

    return (
        <WTable
            {...tableProps}
            afterTable={
                (Children.count(afterTable) > 0 || buttonVisible) && (
                    <>
                        {buttonVisible && (
                            <WButton color="primary" size="bar" variant="border" action={toggleViewAll}>
                                {viewAll ? `View Last ${limit} Records` : "View All Records"}
                            </WButton>
                        )}

                        {afterTable}
                    </>
                )
            }
        >
            {head}
            <tbody>
                {shownItems.length > 0 ? shownItems.map((item, index) => children(item, index)) : <EmptyRow />}
            </tbody>
        </WTable>
    );
}
