import type { ManagedService } from "@warrenio/api-spec/spec.oats.gen";
import { mapFrom, mapOnValues } from "@warrenio/utils/collections/maps";
import { atom } from "jotai/vanilla";
import { mergeLoadedQueries, type QMergedResult } from "../../utils/query/mergeQueries.ts";
import { allIpQueryAtom, type IpAddressWithType } from "../network/ipAddress/apiOperations.ts";
import { UNASSIGNED_RESOURCE } from "../network/ipAddress/resourceId.ts";
import { pricesAtom } from "../pricing/query.ts";
import { getServicePrice, type Price } from "../pricing/resourcePricing.ts";
import { allServicesQueryAtom, type ManagedServiceLoc } from "./servicesQuery.ts";

export type ManagedServiceWithAssigned = ManagedServiceLoc & {
    price: Price;
    assignedPublicIp: IpAddressWithType | UNASSIGNED_RESOURCE;
};

export type AssignedServiceResponse = Map<ManagedService["uuid"], ManagedServiceWithAssigned>;

export const allAssignedServicesQueryAtom = atom((get): QMergedResult<AssignedServiceResponse | undefined> => {
    const results = {
        services: get(allServicesQueryAtom),
        ips: get(allIpQueryAtom),
        prices: get(pricesAtom),
    };
    return mergeLoadedQueries(results, ({ services, ips, prices }) => {
        const ipsByAssignedTo = mapFrom(ips.values(), (ip) => ip.assigned_to);

        return mapOnValues(services, (service): ManagedServiceWithAssigned => {
            const { uuid } = service;
            return {
                ...service,
                price: getServicePrice(prices, service),
                assignedPublicIp: ipsByAssignedTo?.get(uuid) ?? UNASSIGNED_RESOURCE,
            };
        });
    });
});
