import { useState } from "react";
import { WSearchField } from "../components/forms/WSearchField.tsx";
import { WTable, WTableBody } from "../components/table/WTable.tsx";
import { AdminTitle } from "./AdminTitle.tsx";

export function UsersView() {
    const [queryString, setQueryString] = useState("");

    return (
        <>
            <AdminTitle title="Users" />

            <WTable adminTable search={<WSearchField value={queryString} onChange={setQueryString} />}>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Created At</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    <tr />
                </WTableBody>
            </WTable>
        </>
    );
}
