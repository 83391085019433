import type { QueryClient } from "@tanstack/query-core";
import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type {
    LbCreateForwardingRule,
    LbCreateTarget,
    LoadBalancer,
    LoadBalancerCreateBody,
    LoadBalancerRenameBody,
} from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { optimisticPromise } from "../../../utils/query/optimisticPromise.ts";
import { produceQueryData } from "../../../utils/query/produceQueryData.ts";
import { atomFromStandardQueryOptions } from "../../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../../utils/query/runMutation.ts";
import { type ApiClient, getResponseData } from "../../api/apiClient.ts";
import { getResourceIconClass } from "../../api/resourceTypes.tsx";
import { atomAllLocationsQuery } from "../../location/atomAllLocationsQuery.ts";
import { raiseRequestToast, type Toast } from "../../notifications/toast.tsx";
import { getQueryKey as getIpQueryKey } from "../ipAddress/apiOperations.ts";
import { loadBalancerLink } from "../links.ts";

//#region basic Types/Contracts
export interface Params {
    location: string;
    address?: string;
}

export interface LoadBalancerWithType extends LoadBalancer, Params {
    $type: "load_balancer";
}

export type Response = Map<LoadBalancer["uuid"], LoadBalancerWithType>;
const emptyResponse: Response = new Map();

const toastOptions: Partial<Toast> = { icon: getResourceIconClass("load_balancer") };

//#endregion basic Types/Contracts

//#region helper functions
/**
 * Get query key for list of {@link LoadBalancer} according to the location
 * @param params - contains {@link Params} `location` slug for the DC
 */
export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["load_balancer/list"] : ["load_balancer/list", params.location];
}

/**
 * Convert {@link LoadBalancer} to {@link LoadBalancerWithType}
 * @param data - The {@link LoadBalancer} to convert
 * @param location - The location slug for the DC
 */
export function lbFromData(data: LoadBalancer, location: string): LoadBalancerWithType {
    return { ...data, $type: "load_balancer", location };
}

//#endregion helper functions

//#region Queries

/**
 * Get list of {@link LoadBalancer} from one DC location
 * @param apiClient - The {@link ApiClient} to use
 * @param params - contains {@link Params} `location` slug for the DC
 */
export function getSingleQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            const response = getResponseData(
                await apiClient.GET("/{location}/network/load_balancers", {
                    signal,
                    params: { path: { location } },
                }),
            );
            return mapFromEntries(response, (lb) => [lb.uuid, lbFromData(lb, location)]);
        },
    });
}

export const lbQueryAtom = atomFamily((location: string) => atomFromStandardQueryOptions(getSingleQuery, { location }));

export const allLbsQueryAtom = atomAllLocationsQuery(lbQueryAtom, (results) => mergeMaps(results));

//#endregion Queries

//#region Mutations

export function createLoadBalancerMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["load_balancer/create"],
        async mutationFn({ body, location }: { body: LoadBalancerCreateBody; location: string }) {
            const result = getResponseData(
                await api.POST("/{location}/network/load_balancers", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
            return lbFromData(result, location);
        },
        onSuccess(response, { location }) {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        async onSettled(res, err, { location, body: { reserve_public_ip } }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Load Balancer created",
                error: "Failed to create Load Balancer",
                successType: "success",
                viewLink: res ? loadBalancerLink(res) : undefined,
            });

            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
            if (reserve_public_ip) {
                await queryClient.invalidateQueries({ queryKey: getIpQueryKey({ location }) });
            }
        },
    });
}

export function deleteLoadBalancerMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["load_balancer/delete"],
        async mutationFn({ uuid, location }: { uuid: string; location: string }) {
            return getResponseData(
                await api.DELETE("/{location}/network/load_balancers/{uuid}", {
                    params: { path: { location, uuid } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Load Balancer deleted",
                error: "Failed to delete Load Balancer",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function renameLoadBalancerMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["load_balancer/rename"],
        async mutationFn({ uuid, location, body }: { uuid: string; location: string; body: LoadBalancerRenameBody }) {
            const result = getResponseData(
                await api.PATCH("/{location}/network/load_balancers/{uuid}", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location, uuid } },
                }),
            );
            return lbFromData(result, location);
        },
        onSuccess(response, { location }) {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Load Balancer renamed",
                error: "Failed to rename Load Balancer",
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
        },
    });
}

export function changeLoadBalancerBillingAccountMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["load_balancer/change_billing_account"],
        async mutationFn({
            uuid,
            location,
            billing_account_id,
        }: {
            uuid: string;
            location: string;
            billing_account_id: number;
        }) {
            const result = getResponseData(
                await api.PUT("/{location}/network/load_balancers/{uuid}/billing_account", {
                    params: { path: { location, uuid }, query: { set_id: String(billing_account_id) } },
                }),
            );
            return lbFromData(result, location);
        },
        onSuccess(response, { location }) {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Load Balancer billing account changed",
                error: "Failed to change Load Balancer billing account",
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
        },
    });
}

export function addTargetToLoadBalancerMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["load_balancer/add_target"],
        async mutationFn({ uuid, location, body }: { uuid: string; location: string; body: LbCreateTarget }) {
            return getResponseData(
                await api.POST("/{location}/network/load_balancers/{uuid}/targets", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location, uuid } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Target added to Load Balancer",
                error: "Failed to add target to Load Balancer",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function unlinkTargetFromLoadBalancerMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["load_balancer/unlink_target"],
        async mutationFn({ uuid, location, target_uuid }: { uuid: string; location: string; target_uuid: string }) {
            return getResponseData(
                await apiClient.DELETE("/{location}/network/load_balancers/{uuid}/targets/{target_uuid}", {
                    params: { path: { location, uuid, target_uuid } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Target removed from Load Balancer",
                error: "Failed to remove target from Load Balancer",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function addForwardingRuleToLoadBalancerMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["load_balancer/add_forwarding_rule"],
        async mutationFn({ body, ...path }: { uuid: string; location: string; body: LbCreateForwardingRule }) {
            return getResponseData(
                await apiClient.POST("/{location}/network/load_balancers/{uuid}/forwarding_rules", {
                    ...jsonEncodedBody,
                    body,
                    params: { path },
                }),
            );
        },
        onSuccess() {
            //TODO: Get the load balancer and update the forwarding rule list
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Forwarding rule added to Load Balancer",
                error: "Failed to add forwarding rule to Load Balancer",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function dropForwardingRuleFromLoadBalancerMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["load_balancer/unlink_forwarding_rule"],
        async mutationFn({ uuid, location, rule_uuid }: { uuid: string; location: string; rule_uuid: string }) {
            return getResponseData(
                await apiClient.DELETE("/{location}/network/load_balancers/{uuid}/forwarding_rules/{rule_uuid}", {
                    params: { path: { location, uuid, rule_uuid } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Forwarding rule removed from Load Balancer",
                error: "Failed to remove forwarding rule from Load Balancer",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

//#endregion Mutations
