import { useAtom } from "jotai/react";
import { ActionMenu, type ActionMenuItemProps } from "../../components/ActionMenu.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { savedColorSchemeAtom, type ColorSchemeMode } from "./ColorSchemeSwitcher.store.ts";

const icons: Record<ColorSchemeMode, string> = {
    light: "jp-brightness-max-icon",
    dark: "jp-brightness-dark-icon",
    auto: "jp-brightness-medium-icon",
};

const items = [
    { id: "light", title: "Light", icon: "jp-brightness-max-icon color-primary size-1rem" },
    { id: "dark", title: "Dark", icon: "jp-brightness-dark-icon color-primary size-1rem" },
    { id: "auto", title: "Auto", icon: "jp-brightness-medium-icon color-primary size-1rem" },
] satisfies ({ id: ColorSchemeMode } & ActionMenuItemProps)[];

export function ChangeColorSchemeMenu() {
    const [scheme, setScheme] = useAtom(savedColorSchemeAtom);

    return (
        <ActionMenu header="Appearance" onAction={({ id }) => setScheme(id)} selectedKeys={[scheme]} items={items}>
            <WButton
                color="primary"
                variant="ghost"
                size="bar"
                icon={icons[scheme]}
                ariaLabel="Appearance"
                action={undefined}
            />
        </ActionMenu>
    );
}

export default ChangeColorSchemeMenu;
