const baseObjectSymbol = Symbol.for("objectAssignProxy/base");
const otherObjectSymbol = Symbol.for("objectAssignProxy/other");

/**
 * A proxy-based version of object splatting, ie. `{ ...base, ...other }`.
 *
 * Useful for eg. React Query results, which subscribe to updates dynamically based on the properties accessed (eg. `isSuccess` etc.)
 */
export function objectExtendProxy<T extends object, TOther extends object>(base: T, other: TOther) {
    const prevBase = base[baseObjectSymbol as keyof T] as T;

    // Do not create nested proxies for better performance
    if (prevBase !== undefined) {
        const prevOther = base[otherObjectSymbol as keyof T] as Partial<T>;
        base = prevBase;
        other = { ...prevOther, ...other };
    }

    return new Proxy(base, {
        get(target, key: string | symbol) {
            if (key === baseObjectSymbol) {
                return target;
            } else if (key === otherObjectSymbol) {
                return other;
            }
            return Object.prototype.hasOwnProperty.call(other, key)
                ? other[key as keyof TOther]
                : target[key as keyof T];
        },
    }) as T & TOther;
}
