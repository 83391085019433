import type { Atom, Getter } from "jotai/vanilla";

export function getAtomObjectValues<TObject extends Record<string, Atom<unknown>>>(get: Getter, obj: TObject) {
    const result: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(obj)) {
        result[key] = get(value);
    }
    return result as {
        [K in keyof TObject]: TObject[K] extends Atom<infer U> ? U : never;
    };
}
