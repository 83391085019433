export class DatetimeError extends Error {
    name = "DatetimeError";
}

export function datetimeZToDate(str: string) {
    const match = str.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/);
    if (!match) {
        throw new DatetimeError(`Invalid datetime_z: ${str}`);
    }
    const [, year, month, day, hour, minute, second] = match;
    return new Date(Date.UTC(+year, +month - 1, +day, +hour, +minute, +second));
}

export function datetimeTzToDate(str: string) {
    const match = str.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})([+-])(\d{2})(\d{2})$/);
    if (!match) {
        throw new DatetimeError(`Invalid datetime_tz: ${str}`);
    }
    const [, year, month, day, hour, minute, second, ms, sign, offsetHour, offsetMinute] = match;
    const date = new Date(Date.UTC(+year, +month - 1, +day, +hour, +minute, +second, +ms));
    const offset = (sign === "+" ? 1 : -1) * (+offsetHour * 60 + +offsetMinute);
    return new Date(date.getTime() + offset * 60 * 1000);
}

export function datetimeToDate(str: string) {
    const match = str.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/);
    if (!match) {
        throw new DatetimeError(`Invalid datetime: ${str}`);
    }
    const [, year, month, day, hour, minute, second] = match;
    return new Date(Date.UTC(+year, +month - 1, +day, +hour, +minute, +second));
}

/**
 * ISO 8601 datetime with microseconds
 * @example "2019-10-02 08:43:32.263834"
 */
export function datetimeUsecToDate(str: string) {
    const match = str.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})\.(\d{6})$/);
    if (!match) {
        throw new DatetimeError(`Invalid datetime_usec: ${str}`);
    }
    const [, year, month, day, hour, minute, second, usec] = match;
    return new Date(Date.UTC(+year, +month - 1, +day, +hour, +minute, +second, +usec / 1000));
}

/** Converts seconds since the epoch into a {@link Date} */
export function unixToDate(timestamp: number) {
    return new Date(timestamp);
}

export function apiUnixToDate(value: number): Date {
    if (value >= 1_000_000_000_000) {
        return unixToDate(value);
    } else if (value >= 1_000_000_000) {
        // TODO: This will break in the far future
        return unixToDate(value * 1000);
    } else {
        throw new DatetimeError(`Unknown Unix timestamp format: ${value}`);
    }
}

/** Convert a string representing a datetime from the API into a JavaScript {@link Date} object. */
export function apiDatetimeToDate(value: string): Date {
    switch (value.length) {
        case 7:
            return datetimeToDate(value + "-01 00:00:00");
        case 19:
            return datetimeToDate(value);
        case 20:
            return datetimeZToDate(value);
        case 26:
            return datetimeUsecToDate(value);
        case 28:
            return datetimeTzToDate(value);
        default:
            throw new DatetimeError(`Unknown datetime format: ${value}`);
    }
}
