import { asArray } from "./asArray.ts";

/**
 * Filter out elements that are "false" according to React's definition
 *
 * (ie. false, null or undefined - but not 0 or "" which are considered "truthy")
 */
export function filterFalse<T>(obj: Iterable<T | false | null | undefined>): T[] {
    return asArray(obj).filter((x): x is T => x !== false && x != null);
}
