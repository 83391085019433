import type { QueryClient } from "@tanstack/react-query";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useEffect } from "react";
import { event_mockDbHotReload } from "./db.events.ts";

/** Invalidates the query client's cache on mock DB code changes */
export function useQueryHotReload(queryClient: QueryClient) {
    if (!import.meta.env.DEV) return;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        function onReload() {
            console.debug("Hot reload DB: invalidate all queries");
            discardPromise(queryClient.invalidateQueries());
        }

        document.addEventListener(event_mockDbHotReload, onReload);
        return () => document.removeEventListener(event_mockDbHotReload, onReload);
    }, [queryClient]);
}
