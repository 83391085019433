import NiceModal from "@ebay/nice-modal-react";
import { Outlet } from "@tanstack/react-router";
import { useAtomValue } from "jotai/react";
import { type ComponentType, type ReactNode, Suspense } from "react";
import { Fragment } from "react/jsx-runtime";
import { DoNotSuspendHere } from "../../components/dev/DoNotSuspendHere.tsx";
import { MainLayoutContentLoadingSuspense } from "../../components/loading/Loading.tsx";
import { currentApiKeyAtom } from "../api/apikey.store.ts";
import { Toaster } from "../notifications/Toaster.tsx";

/** Root component inside the router. Contains various providers & utility wrappers. */
export function BaseRouterRoot({
    Layout,
}: {
    /** Component to wrap the actual page content in. */
    Layout: ComponentType<{ children: ReactNode }>;
}) {
    const resetKey = useAtomValue(currentApiKeyAtom);
    // NB: NiceModal must be inside router so modals can access the router context
    return (
        // NB: Re-create ALL components when the API key changes since React Query has a bug where it does not
        // invalidate queries when the query client changes.
        <Fragment key={resetKey}>
            <Suspense fallback={<DoNotSuspendHere />}>
                <NiceModal.Provider>
                    <Layout>
                        <MainLayoutContentLoadingSuspense>
                            <Outlet />
                        </MainLayoutContentLoadingSuspense>
                    </Layout>
                    <Toaster />
                </NiceModal.Provider>
            </Suspense>
        </Fragment>
    );
}
