import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { Location } from "@warrenio/api-spec/spec.oats.gen";
import { getAndAssert } from "@warrenio/utils/collections/getAndAssert";
import { mapFromEntries } from "@warrenio/utils/collections/maps";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";
import type { ResourceType } from "../api/resourceTypes.tsx";
import { getLocationsForType, type LocationWithEnabled, type LocationsForType } from "./location.ts";

export type LocationSlug = string;

export type Response = Map<LocationSlug, Location>;

export const queryKey: QueryKey = ["locations"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> =>
            mapFromEntries(getResponseData(await client.GET("/config/locations", { signal })), (item) => [
                item.slug,
                item,
            ]),
    });
}

export const queryAtom = atomFromStandardQueryOptions(getQuery);

/** NB: Only use this for non-resource purposes.
 * @see {@link useLocationsForType}
 */
function useAllLocationsSuspense() {
    return useSuspenseQueryAtom(queryAtom);
}

export function usePricingLocations() {
    const data = useAllLocationsSuspense();
    // For viewing pricing, all locations can be selected
    return [...data.values()].map((l): LocationWithEnabled => ({ ...l, isEnabled: true }));
}

export function useSingleLocationSuspense(slug: LocationSlug) {
    const data = useAllLocationsSuspense();
    return getAndAssert(data, slug, "Location");
}

export function useLocationsForType(resourceType: ResourceType | undefined): LocationsForType {
    const data = useAllLocationsSuspense();
    return getLocationsForType([...data.values()], resourceType);
}
