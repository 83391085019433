import type { QueryClient } from "@tanstack/query-core";
import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type {
    AccessKeyDeleteBody,
    ObjectStorageCreateBody,
    ObjectStorageUpdateBody,
    S3Url,
    Storage,
    StorageKey,
} from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries } from "@warrenio/utils/collections/maps";
import { urlEncodedBody } from "../../../utils/fetchClient.ts";
import { optimisticPromise } from "../../../utils/query/optimisticPromise.ts";
import { produceQueryData } from "../../../utils/query/produceQueryData.ts";
import { mutationOptions } from "../../../utils/query/runMutation.ts";
import { type ApiClient, getResponseData } from "../../api/apiClient.ts";
import { getResourceIconClass } from "../../api/resourceTypes.tsx";
import { raiseRequestToast, type Toast } from "../../notifications/toast.tsx";
import type { ItemWithPrice } from "../../pricing/resourcePricing.ts";
import { objectStorageLink } from "../links.ts";

//#region Types/Contracts
export interface Params {
    location: string;
}

export interface StorageWithType extends Storage {
    $type: "bucket";
}

export interface StorageWithPrice extends StorageWithType, ItemWithPrice {}

export type Response = Map<Storage["name"], StorageWithType>;
const emptyResponse: Response = new Map();

const toastOptions: Partial<Toast> = { icon: getResourceIconClass("bucket") };

//#endregion

//#region helper functions

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["storage/bucket/list"] : ["storage/bucket/list", params.location];
}

export function bucketFromData(data: Storage): StorageWithType {
    return { ...data, $type: "bucket" };
}

//#endregion helper functions

//#region Queries

/** @return Map of {@link Storage}s keyed by name */
export function objectStorageListQuery(apiClient: ApiClient, params?: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }) => {
            const response = getResponseData(await apiClient.GET("/storage/bucket/list", { signal }));

            return mapFromEntries(response, (o) => [o.name, bucketFromData(o)]);
        },
    });
}

export function getS3ApiUrlQuery(apiClient: ApiClient) {
    return queryOptions({
        queryKey: ["s3_url"],
        queryFn: async ({ signal }): Promise<S3Url> =>
            getResponseData(await apiClient.GET("/storage/api/s3", { signal })),
    });
}

const storageKeysQueryKey: QueryKey = ["object_storage_keys"];

export function getUserObjectStorageKeysQuery(apiClient: ApiClient) {
    return queryOptions({
        queryKey: storageKeysQueryKey,
        queryFn: async ({ signal }): Promise<StorageKey[]> =>
            getResponseData(await apiClient.GET("/storage/user/keys", { signal })),
    });
}

//#endregion Queries

//#region Mutations

export function createObjectStorageMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["storage/bucket/create"],
        async mutationFn({ body }: { body: ObjectStorageCreateBody }) {
            const result = getResponseData(
                await apiClient.PUT("/storage/bucket", {
                    ...urlEncodedBody,
                    body,
                }),
            );
            return bucketFromData(result);
        },
        onSuccess(res) {
            produceQueryData(queryClient, getQueryKey(), emptyResponse, (data) => {
                data.set(res.name, res);
            });
        },
        onSettled(res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Object storage created",
                error: "Failed to create object storage",
                successType: "success",
                viewLink: res ? objectStorageLink(res) : undefined,
            });

            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey() }));
        },
    });
}

export function deleteObjectStorageMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["storage/bucket/delete"],
        async mutationFn({ name }: { name: string }) {
            return getResponseData(
                await apiClient.DELETE("/storage/bucket", {
                    ...urlEncodedBody,
                    body: { name },
                }),
            );
        },

        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Object storage deleted",
                error: "Failed to delete object storage",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey() });
        },
    });
}

export function changeObjectStorageBAMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["storage/bucket/change_billing_account"],
        async mutationFn({ body }: { body: ObjectStorageUpdateBody }) {
            return getResponseData(
                await apiClient.PATCH("/storage/bucket", {
                    ...urlEncodedBody,
                    body,
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Object storage Billing account changed",
                error: "Failed to change billing account on object storage",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey() });
        },
    });
}

export function generateObjectStorageKeyMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["storage/bucket/key/create"],
        async mutationFn() {
            return getResponseData(await apiClient.POST("/storage/user/keys"));
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Storage access key generated",
                error: "Failed to generate storage access key",
            });
            await queryClient.invalidateQueries({ queryKey: storageKeysQueryKey });
        },
    });
}

export function deleteObjectStorageKeyMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["storage/bucket/key/delete"],
        async mutationFn({ body }: { body: AccessKeyDeleteBody }) {
            return getResponseData(
                await apiClient.DELETE("/storage/user/keys", {
                    ...urlEncodedBody,
                    body,
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Storage access key deleted",
                error: "Failed to delete storage access key",
            });
            await queryClient.invalidateQueries({ queryKey: storageKeysQueryKey });
        },
    });
}

//#endregion Mutations
