import { mapFromEntries, mapOnValues, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { atom } from "jotai/vanilla";
import { LOADING } from "../../../utils/loading.ts";
import { getAtomObjectValues } from "../../../utils/query/getAtomObjectValues.ts";
import {
    getQueriesData,
    mergeLoadedQueries,
    mergeQueries,
    mergeQueryStatuses,
    type QMergedResult,
} from "../../../utils/query/mergeQueries.ts";
import type * as vmQuery from "../../compute/vmQuery.ts";
import { vmQueryAtom, type VirtualMachineLoc } from "../../compute/vmQuery.ts";
import { atomAllLocationsQuery } from "../../location/atomAllLocationsQuery.ts";
import { pricesAtom, type Response as PricesResponse } from "../../pricing/query.ts";
import { getIpAddressPrice, getLoadBalancerPrice, type ItemWithPrice } from "../../pricing/resourcePricing.ts";
import type * as serviceQuery from "../../services/servicesQuery.ts";
import { allServicesQueryAtom, type ManagedServiceLoc } from "../../services/servicesQuery.ts";
import { UNKNOWN_RESOURCE } from "../genericResources.ts";
import type * as lbQuery from "../loadbalancer/apiOperations.ts";
import { lbQueryAtom, type LoadBalancerWithType } from "../loadbalancer/apiOperations.ts";
import type * as ipQuery from "./apiOperations.ts";
import { ipQueryAtom, type IpAddressWithType } from "./apiOperations.ts";
import { UNASSIGNED_RESOURCE } from "./resourceId.ts";

export interface IpAddressWithAssigned extends IpAddressWithType {
    assignedResource:
        | VirtualMachineLoc
        | LoadBalancerWithType
        | ManagedServiceLoc
        | LOADING
        | UNKNOWN_RESOURCE
        | UNASSIGNED_RESOURCE;
}

export interface LoadBalancerWithAssigned extends LoadBalancerWithType {
    assignedPublicIp: IpAddressWithType | UNASSIGNED_RESOURCE;
}

export interface LoadBalancerWithPrice extends LoadBalancerWithAssigned, ItemWithPrice {}
export interface IpAddressWithPrice extends IpAddressWithAssigned, ItemWithPrice {}

export type AssignedResponse = Map<string, IpAddressWithPrice | LoadBalancerWithPrice>;

/** Query that joins load balancers to IPs that are assigned to it */
export const assignedLbsQueryAtom = atomFamily((location: string) => {
    const lbQuery = lbQueryAtom(location);
    const ipQuery = ipQueryAtom(location);

    return atom((get): QMergedResult<Map<string, LoadBalancerWithPrice> | undefined> => {
        const results = {
            lbs: get(lbQuery),
            ips: get(ipQuery),
            prices: get(pricesAtom),
        };
        // STRATEGY 1: Wait for queries to be fully loaded before joining
        return mergeLoadedQueries(results, (loaded) => {
            const items = joinLoadBalancers(loaded);
            return mapOnValues(items, (item) => {
                return {
                    ...item,
                    price: getLoadBalancerPrice(loaded.prices, item.location, false),
                };
            });
        });
    });
});

export const allAssignedLbsQueryAtom = atomAllLocationsQuery(assignedLbsQueryAtom, (results) => mergeMaps(results));

/** Query that joins IPs to resources that are assigned to it */
export const assignedIpsQueryAtom = atomFamily((location: string) => {
    const ipQuery = ipQueryAtom(location);
    const vmQuery = vmQueryAtom(location);
    const lbQuery = lbQueryAtom(location);

    return atom((get): QMergedResult<Map<string, IpAddressWithPrice> | undefined> => {
        const results = {
            ips: get(ipQuery),
            vms: get(vmQuery),
            lbs: get(lbQuery),
            services: get(allServicesQueryAtom),
            prices: get(pricesAtom),
        };
        // STRATEGY 2: Join as soon as possible, even if some queries are still loading (by hard-coding the results merge)
        return mergeQueryStatuses(results, joinIps(getQueriesData(results)));
    });
});

export const allAssignedIpsQueryAtom = atomAllLocationsQuery(assignedIpsQueryAtom, (results) => mergeMaps(results));

/** Query that joins IPs & LBs to resources that are assigned to them */
export const assignedLbAndIpsQueryAtom = atomFamily((location: string) => {
    const queryAtoms = {
        lbs: assignedLbsQueryAtom(location),
        ips: assignedIpsQueryAtom(location),
    };
    return atom((get): QMergedResult<AssignedResponse> => {
        const results = getAtomObjectValues(get, queryAtoms);
        // STRATEGY 2.1: Use the `mergeQueries` utility function to join the queries
        return mergeQueries(results, (maps) => mergeMaps(maps));
    });
});

export const allAssignedLbAndIpsQueryAtom = atomAllLocationsQuery(assignedLbAndIpsQueryAtom, (results) =>
    mergeMaps(results),
);

interface QueryResults {
    ips: ipQuery.Response | undefined;
    vms: vmQuery.Response | undefined;
    lbs: lbQuery.Response | undefined;
    services: serviceQuery.Response | undefined;
    prices: PricesResponse | undefined;
}

function joinIps({ ips, vms, lbs, services, prices }: QueryResults) {
    if (!ips || !prices) {
        return undefined;
    }

    // If any of the queries are still loading, we can not say for sure that the resource was not found
    const assignedResourceFallback = vms && lbs ? UNKNOWN_RESOURCE : LOADING;
    return mapOnValues(ips, (ip): IpAddressWithPrice => {
        const { assigned_to } = ip;
        return {
            ...ip,
            assignedResource: assigned_to
                ? (vms?.get(assigned_to) ??
                  lbs?.get(assigned_to) ??
                  services?.get(assigned_to) ??
                  assignedResourceFallback)
                : UNASSIGNED_RESOURCE,
            price: getIpAddressPrice(prices, ip),
        };
    });
}

function joinLoadBalancers({ lbs, ips }: { lbs: lbQuery.Response; ips: ipQuery.Response }) {
    const ipsByAssignedTo = mapFromEntries(ips, ([_, ip]) => [ip.assigned_to, ip]);

    return mapOnValues(lbs, (lb): LoadBalancerWithAssigned => {
        const { uuid } = lb;
        return {
            ...lb,
            assignedPublicIp: ipsByAssignedTo?.get(uuid) ?? UNASSIGNED_RESOURCE,
        };
    });
}
