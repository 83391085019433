import type { VirtualMachine } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import type { HostPoolUUID } from "../pools/query.ts";

/** Both fields are optional to support both "Create" and existing VM use cases */
export type VmPoolFields = Partial<Pick<VirtualMachine, "current_pool_uuid" | "designated_pool_uuid">>;

export function getRequiredVmPool(vm: VmPoolFields): HostPoolUUID {
    return notNull(getOptionalVmPool(vm), "VM pool uuid");
}

export function getOptionalVmPool(vm: VmPoolFields): HostPoolUUID | null {
    return vm.current_pool_uuid ?? vm.designated_pool_uuid ?? null;
}
