import { memo, useMemo } from "react";
import { mcn, type BaseProps } from "../../utils/baseProps";
import { globalRandom, type RandomFn } from "../../utils/random";

export interface SkeletonProps extends BaseProps {
    /** Number of lines */
    lines?: number;
    /** Number of words per line */
    words?: number;
    /** Whether the text looks justified or ragged */
    justified?: boolean;
    /** Random number generator */
    rng?: RandomFn;
}

/**
 * Display a loading component that looks like grayed out text.
 * The word height adjusts to the current font size.
 * The width is 100% of the parent container.
 */
export const Skeleton = memo(function Skeleton({
    lines = 3,
    words = 4,
    justified = lines === 1,
    rng = globalRandom,
    ...props
}: SkeletonProps) {
    if (!justified) {
        words += 1;
    }
    const linesArr = useMemo(() => makeWordWidthArray(lines, words, justified, rng), [lines, words, justified, rng]);

    // TODO: automatically figure out suitable bg color (current text color with 50% luminance?)
    return (
        <div {...mcn("flex flex-col gap-[0.4em]", props)}>
            {linesArr.map((wordsArr, i) => (
                <div key={i} className="flex flex-row gap-[0.5em]">
                    {wordsArr.map((width, j) => {
                        const isSpacer = !justified && j === words - 1;
                        return (
                            <div
                                key={j}
                                className={`${!isSpacer ? "bg-gray-200 rounded-sm" : ""} h-[1em]`}
                                style={{ flexGrow: `${width}` }}
                            />
                        );
                    })}
                </div>
            ))}
        </div>
    );
});

function _runningSum(values: number[]) {
    const result = new Array(values.length);
    let sum = 0;
    for (let i = 0; i < values.length; i++) {
        sum += values[i];
        result[i] = sum;
    }
    return `${result.join(", ")} = ${sum}`;
}

function makeAddUpTo(desiredSum: number, values: number[]): number[] {
    const sum = values.reduce((a, b) => a + b, 0);
    if (sum === desiredSum) return values;
    const scaleFactor = desiredSum / sum;
    return values.map((v) => v * scaleFactor);
}

function makeRandomArrayWithSum(
    desiredSum: number,
    length: number,
    randomFn: (idx: number) => number = globalRandom,
): number[] {
    const values = new Array<number>(length);
    for (let i = 0; i < length; i++) {
        values[i] = randomFn(i);
    }
    return makeAddUpTo(desiredSum, values);
}

const nominalWordWidth = 100;
const minimumDiff = 40;
const minimumWidth = 30;

/** Prevent typographic rivers by mis-aligning words on consecutive lines  */
function makeWordWidthArray(
    lineCount: number,
    wordCount: number,
    justified: boolean,
    rng: RandomFn = globalRandom,
): number[][] {
    const lines = new Array<number[]>(lineCount);
    // console.group("Line width calculation");
    for (let i = 0; i < lineCount; i++) {
        const curLine = makeRandomArrayWithSum(nominalWordWidth * wordCount, wordCount, (i) => {
            const isSpacer = !justified && i === wordCount - 1;
            // Without justification, make the last "word" smaller, since it is actually empty space.
            // (statistically line-end space is half the width of a normal word)
            return (50 + rng() * 50) * (isSpacer ? 0.5 : 1);
        }).map((v) => Math.round(v));
        lines[i] = curLine;

        const prevLine = i > 0 ? lines[i - 1] : null;

        // console.group("Line %o", i, _runningSum(curLine));

        let curX = 0;
        let prevX = 0;

        let delta = 0;

        for (let j = 0; j < wordCount; j++) {
            // Eat delta from previous word to make sure the sum stays the same
            let curWidth = curLine[j] + delta;
            delta = 0;

            if (prevLine) {
                const nextX = curX + curWidth;
                const prevWidth = prevLine[j];
                prevX += prevWidth;

                const diff = prevX - nextX;

                // Check if a river is forming
                if (j < wordCount - 1 && Math.abs(diff) < minimumDiff) {
                    delta = Math.ceil((minimumDiff - Math.abs(diff)) / 2) * (diff < 0 ? -1 : 1);
                    if (curWidth - delta < minimumWidth) {
                        // console.log("Too small! %o", { delta, curWidth });

                        // Can not reduce the width any further
                        delta = curWidth - minimumWidth;
                    }

                    // Change the width to enlarge the gap
                    curWidth -= delta;

                    // console.log("Delta correction! %o", { delta, curWidth });
                }
            }

            curLine[j] = curWidth;
            curX += curWidth;
        }
        // console.log("Final: %s", _runningSum(curLine));
        // console.groupEnd();
    }
    // console.groupEnd();
    return lines;
}
