import { useAtomValue } from "jotai/react";
import type { Atom } from "jotai/vanilla";
import invariant from "tiny-invariant";
import { useSuspenseAtomValue } from "../jotai/useSuspenseAtomValue.ts";
import type { QResult } from "./mergeQueries.ts";

/** Suspends until the query result (in an atom) is ready.
 *
 * @see {@link useSuspenseQueryAtom} which returns the data directly.
 */
export function useSuspenseQueryResultAtom<TQuery extends QResult<unknown>>(atom: Atom<TQuery>): TQuery {
    return useSuspenseAtomValue(
        atom,
        (result) => !result.isPending,
        (result) => result.error,
    );
}

/**
 * @returns The data of the query when it is available. Suspends otherwise.
 * @see {@link useQueryResultAtom} if you do not want to suspend.
 */
export function useSuspenseQueryAtom<TData>(atom: Atom<QResult<TData>>): TData {
    return useSuspenseQueryResultAtom(atom).data!;
}

/** Suspends until the query data is ready.
 * If the query has an error but a stale result is available, it will be returned. Otherwise, the error will be thrown.
 * @see {@link useSuspenseQueryAtom} which always throws on an error.
 */
export function useSuspenseQueryStaleOnErrorAtom<TData>(atom: Atom<QResult<TData>>): TData {
    const { data, error } = useSuspenseAtomValue(atom, (result) => result.data !== undefined || result.error != null);
    if (data !== undefined) {
        return data;
    }

    invariant(error != null, "Inconsistent state, query must have data or error");
    throw error;
}

/**
 * Immediately return the status of the query (and the data, if available), without suspending.
 *
 * @returns A query result object. Usually, the `data` field will be destructured. The value will be `undefined` if the
 * query is not ready.
 */
export function useQueryResultAtom<TData>(atom: Atom<QResult<TData>>): QResult<TData> {
    const result = useAtomValue(atom);
    if (result.error) {
        throw result.error;
    }
    return result;
}
