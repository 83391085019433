import TC from "./Table.module.css";

import type { ReactNode } from "react";

export interface DetailsTableProps {
    children?: ReactNode;
}

export function DetailsTable({ children }: DetailsTableProps) {
    return (
        <table className={TC.details}>
            <tbody>{children}</tbody>
        </table>
    );
}

export function DetailsHolder({ children }: { children: ReactNode }) {
    return <div className={TC.detailsHolder}>{children}</div>;
}
