import { useAtomValue } from "jotai/react";
import type { ReactNode } from "react";
import { LayoutContent } from "../LayoutContent.tsx";
import { showOnlyContentAtom } from "../MainLayout.store.ts";
import { MobileTopNav } from "../TopNav.tsx";
import { initSections } from "../sidebar/Sidebar.sections.tsx";

export function MobileLayout({ children }: { children?: ReactNode }) {
    const showOnlyContent = useAtomValue(showOnlyContentAtom);
    if (showOnlyContent) {
        return children;
    }

    initSections();

    return (
        <>
            <MobileTopNav />
            <LayoutContent>{children}</LayoutContent>
        </>
    );
}
