import { NotFoundError } from "@warrenio/utils/collections/getAndAssert";
import { getResourceTypeName, type ResourceType } from "./resourceTypes.tsx";

export class ResourceNotFoundError extends NotFoundError {
    name = "ResourceNotFoundError";
    constructor(
        public readonly type: ResourceType,
        id: ConstructorParameters<typeof NotFoundError>[1],
    ) {
        super(getResourceTypeName(type), id);
    }
}

export function getResourceById<T, TId>(resources: Map<TId, T>, id: TId, type: ResourceType): T {
    const resource = resources.get(id);
    if (!resource) {
        throw new ResourceNotFoundError(type, id);
    }
    return resource;
}
