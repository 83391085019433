import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { MetalOsListResponse } from "@warrenio/api-spec/spec.oats.gen";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";

export type Response = MetalOsListResponse;

export const queryKey: QueryKey = ["metal_os"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            return getResponseData(await client.GET("/metal/os", { signal }));
        },
    });
}

export const metalOsQueryAtom = atomFromStandardQueryOptions(getQuery);
