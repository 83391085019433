import { useRouter, type AnyRouter, type ParsedLocation } from "@tanstack/react-router";
import { useSetAtom, useStore } from "jotai/react";
import { useEffect, useMemo } from "react";
import { removePrefix } from "../../../utils/paths";
import { initSections } from "./Sidebar.sections";
import { currentSectionAtom, findSectionForPath, sectionsAtom } from "./Sidebar.store";

/**
 * Synchronize the current section ID with the router's path.
 *
 * Render this component before anything else to make sure the initial section ID is set.
 */
export function SetSectionFromRouter() {
    /**
     * Initialize the sections here (as it is the first component loaded that accesses sections)
     * to prevent cyclic dependencies (see {@link sectionsAtom}).
     */
    initSections();

    const router = useRouter();
    const setSectionId = useSetAtom(currentSectionAtom);
    // Do not need to `useAtom()` here since we do not need to re-render on changes.
    const { get } = useStore();

    // On initial component mount, default to the section for the current URL's path
    useMemo(() => {
        const sectionId = get(currentSectionAtom);
        if (sectionId !== undefined) {
            return;
        }

        const path = getPathFromRouter(router);
        const section = findSectionForPath(get(sectionsAtom), path);
        if (section) {
            console.debug("Initialize nav section", section);
            setSectionId(section);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Monitor for route changes and auto-navigate to corresponding section
    useEffect(() => {
        return router.subscribe("onLoad", (l) => {
            const path = getPathFromRouter(router, l.toLocation);
            const pathSection = findSectionForPath(get(sectionsAtom), path);

            const currentSection = get(currentSectionAtom);
            if (pathSection !== undefined && pathSection !== currentSection) {
                console.log("Auto-navigate to section %o from %o", pathSection, currentSection);
                setSectionId(pathSection);
            }
        });
    }, [get, setSectionId, router]);
    return null;
}

function getPathFromRouter(router: AnyRouter, toLocation: ParsedLocation<any> = router.state.location) {
    return removePrefix(router.basepath, toLocation.pathname);
}
