import type { MetalStatus } from "@warrenio/api-spec/spec.oats.gen";
import type { ReactNode } from "react";
import { Badge } from "../../../components/Badge.tsx";
import { getMetalStatusInfo } from "./StatusLabels.tsx";

export function MetalStatusBadge({ value, reverse }: { value: MetalStatus; reverse?: boolean }): ReactNode {
    const { color, title } = getMetalStatusInfo(value);
    return (
        <Badge reverse={reverse} color={color}>
            {title}
        </Badge>
    );
}
