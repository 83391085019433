let nextErrorId = 1;
const idRegistry = new WeakMap<Error, number>();

/**
 * Generate a unique ID for an error object.
 */
export function getErrorId(error: Error): number {
    let id;
    if (!idRegistry.has(error)) {
        id = nextErrorId++;
        idRegistry.set(error, id);
    } else {
        id = idRegistry.get(error)!;
    }
    return id;
}
