import type { QueryClient } from "@tanstack/query-core";
import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { User, UserMfaResponse, UserProfilePatchRequest } from "@warrenio/api-spec/spec.oats.gen";
import { MINUTES } from "@warrenio/utils/timeUnits";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../utils/query/runMutation.ts";
import * as impersonationApi from "../access/impersonation/apiOperations.ts";
import { getResponseData, throwApiException, type ApiClient } from "../api/apiClient.ts";
import { raiseRequestToast, type Toast } from "../notifications/toast.tsx";

//#region Queries
const queryKey: QueryKey = ["/user_resource/user"];

function userQuery(apiClient: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<User> => {
            return getResponseData(await apiClient.GET("/user-resource/user", { signal }));
        },
    });
}

export const userDataAtom = atomFromStandardQueryOptions(userQuery);

//#region Admin
async function fetchUserIsAdmin(apiClient: ApiClient, signal: AbortSignal): Promise<boolean> {
    const result = await apiClient.GET("/user-resource/user/privileges", { signal });
    const {
        response,
        error,
        response: { status },
    } = result;
    // Esoteric way of checking if user is admin... (yes, 404 means user is an admin)
    if (status === 403) {
        return false;
    } else if (status === 404 || status === 502 || (status >= 200 && status < 300)) {
        return true;
    } else {
        throwApiException(response, error);
    }
}

function userIsAdminQuery(apiClient: ApiClient) {
    return queryOptions({
        queryKey: ["user_privileges"],
        queryFn: async ({ signal }) => await fetchUserIsAdmin(apiClient, signal),
        staleTime: 60 * MINUTES,
    });
}

export const userIsAdminAtom = atomFromStandardQueryOptions(userIsAdminQuery);
//#endregion

const mfaQueryKey: QueryKey = ["mfa_is_active"];

export function userMfaQuery(apiClient: ApiClient) {
    return queryOptions({
        queryKey: mfaQueryKey,
        queryFn: async ({ signal }): Promise<UserMfaResponse> => {
            return getResponseData(await apiClient.GET("/user-resource/mfa/is_active", { signal }));
        },
    });
}

//#endregion

//#region Mutations
const toastOptions: Partial<Toast> = { icon: "jp-user-icon" };

export function changeProfileDataMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["/user-resource/user/profile"],
        async mutationFn({ body }: { body: UserProfilePatchRequest }) {
            return getResponseData(
                await apiClient.PATCH("/user-resource/user/profile", {
                    ...jsonEncodedBody,
                    body,
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "User details updated",
                error: "Failed to update details",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function activateMfaMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["mfa_activate"],
        async mutationFn() {
            return getResponseData(await apiClient.POST("/user-resource/mfa/activate"));
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Mfa activated",
                error: "Failed to activate MFA",
                successType: "success",
            });
            await queryClient.invalidateQueries({ queryKey: mfaQueryKey });
        },
    });
}

export function deactivateMfaMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["mfa_deactivate"],
        async mutationFn() {
            return getResponseData(await apiClient.POST("/user-resource/mfa/deactivate"));
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Mfa deactivated",
                error: "Failed to deactivate MFA",
            });
            await queryClient.invalidateQueries({ queryKey: mfaQueryKey });
        },
    });
}

export function acceptInvitationMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["accept_invitation"],
        async mutationFn({ uuid }: { uuid: string }) {
            return getResponseData(
                await apiClient.PUT("/user-resource/impersonation/{uuid}/accept", {
                    params: { path: { uuid } },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Access invitation accepted",
                error: "Failed to accept invitation",
                successType: "success",
            });
            await queryClient.invalidateQueries({ queryKey: impersonationApi.queryKey });
        },
    });
}

//#endregion Mutations
