import { queryOptions, type QueryClient, type QueryKey } from "@tanstack/react-query";
import type { AccessDelegation, AccessGroupCreateBody } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries } from "@warrenio/utils/collections/maps";
import { parallelMap } from "@warrenio/utils/collections/parallelMap";
import { urlEncodedBody } from "../../../utils/fetchClient.ts";
import { optimisticPromise } from "../../../utils/query/optimisticPromise.ts";
import { produceQueryData } from "../../../utils/query/produceQueryData.ts";
import { atomFromStandardQueryOptions } from "../../../utils/query/queryAtom.ts";
import { mutationOptions, runMutation } from "../../../utils/query/runMutation.ts";
import { getResponseData, type ApiClient } from "../../api/apiClient.ts";
import { getResourceIconClass } from "../../api/resourceTypes.tsx";
import { raiseRequestToast, type RequestToastOptions, type Toast } from "../../notifications/toast.tsx";
import { delegationLink } from "../links.ts";

//#region basic Types/Contracts

export type Response = Map<AccessDelegation["id"], AccessDelegationWithType>;

export interface AccessDelegationWithType extends AccessDelegation {
    $type: "access_delegation";
    restricted: boolean;
}

export const emptyResponse: Response = new Map();

const queryKey: QueryKey = ["/user-resource/access_group"];

//#endregion

//#region Queries

export function accessDelegationFromData(data: AccessDelegation): AccessDelegationWithType {
    return { ...data, $type: "access_delegation", restricted: !!data.billing_account_id };
}

export function accessDelegationListQuery(apiClient: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            const response = getResponseData(await apiClient.GET("/user-resource/access_group", { signal }));

            return mapFromEntries(response, (o) => [o.id, accessDelegationFromData(o)]);
        },
    });
}

export const accessDelegationListQueryAtom = atomFromStandardQueryOptions(accessDelegationListQuery);

//#endregion

//#region Mutations
const toastOptions: Partial<Toast> = { icon: getResourceIconClass("access_delegation") };

function delegationCreateMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["access_delegation/create"],
        async mutationFn({ body }: { body: AccessGroupCreateBody }) {
            return getResponseData(
                await api.POST("/user-resource/access_group", {
                    ...urlEncodedBody,
                    body,
                }),
            );
        },
        onSuccess: (response, _mutation) => {
            produceQueryData(queryClient, queryKey, emptyResponse, (draft) => {
                draft.set(response.id, accessDelegationFromData(response));
            });
        },
        onSettled(res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Delegation Access Group created",
                error: "Error creating Delegation Access Group",
                successType: "success",
                viewLink: res ? delegationLink(res) : undefined,
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey }));
        },
    });
}

/**
 * Joint mutation to create a delegation and add emails to it.
 * Better than `use()`ing two mutations in a row, because eg. the `isPending` and `isSuccess` states are easier to track.
 */
export function createFullAccessDelegationMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationFn: async ({ body, emails = [] }: { body: AccessGroupCreateBody; emails?: string[] }) => {
            const result = await runMutation(queryClient, delegationCreateMutation(api, queryClient), { body });
            const emailResults = await parallelMap(emails, async (email) => {
                return await runMutation(queryClient, addImpersonatorMutation(api, queryClient, { silent: true }), {
                    delegation_id: result.id,
                    grantee_username: email,
                });
            });
            return { result, emailResults };
        },
    });
}

export function deleteAccessDelegationMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["access_delegation/delete"],
        async mutationFn({ id }: { id: AccessDelegation["id"] }) {
            return getResponseData(
                await api.DELETE("/user-resource/access_group/{id}", {
                    params: { path: { id: String(id) } },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Access Delegation deleted",
                error: "Error deleting Access Delegation",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function addImpersonatorMutation(
    api: ApiClient,
    queryClient: QueryClient,
    mutationToastOptions: Partial<RequestToastOptions> = {},
) {
    return mutationOptions({
        mutationKey: ["access/impersonation/create"],
        async mutationFn({ delegation_id, grantee_username }: { delegation_id: number; grantee_username: string }) {
            return getResponseData(
                await api.POST("/user-resource/access_group/{delegation_id}/impersonation", {
                    ...urlEncodedBody,
                    body: { grantee_username },
                    params: {
                        path: { delegation_id: String(delegation_id) },
                    },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Access Delegation user added",
                error: "Error adding Access Delegation user",

                ...mutationToastOptions,
            });
            await queryClient.invalidateQueries({ queryKey });
        },
        onSuccess(_response, _mutation) {
            // TODO: Optimistic mutation
        },
    });
}

export function deleteImpersonatorMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["access/impersonation/delete"],
        async mutationFn({ delegation_id, impersonation_id }: { delegation_id: number; impersonation_id: string }) {
            return getResponseData(
                await api.DELETE("/user-resource/access_group/{delegation_id}/impersonation/{impersonation_id}", {
                    params: {
                        path: { delegation_id: String(delegation_id), impersonation_id: String(impersonation_id) },
                    },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Access Delegation user deleted",
                error: "Error deleting Access Delegation user",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

//#endregion
