import C from "./NoticeBlock.module.css";

import type { ReactNode } from "react";
import { cn } from "../utils/classNames.ts";
import { MaskIcon } from "./icon/MaskIcon.tsx";

const iconColors = {
    primary: "color-primary",
    error: "color-error",
    success: "color-success",
};

export interface NoticeBlockProps {
    children: ReactNode;

    color?: "primary" | "error" | "success";
    icon?: string;
    button?: ReactNode;
    compact?: boolean;
    full?: boolean;
}

export function NoticeBlock({ children, color = "primary", icon, button, compact, full }: NoticeBlockProps) {
    return (
        <div className={cn(C.NoticeBlock, C[color], compact && C.compact, full && C.full)}>
            {icon ? (
                <MaskIcon className={`${icon} ${compact ? "size-0.75rem" : "size-2.5rem"} ${iconColors[color]}`} />
            ) : null}
            <div className={C.Content}>{children}</div>
            {button}
        </div>
    );
}
