import C from "./TopNav.module.css";

import { Link } from "@tanstack/react-router";
import React, { Suspense, memo } from "react";
import { IsolatedChildren } from "../../components/Isolated.tsx";
import { Spacer } from "../../components/Spacer";
import { WButton } from "../../components/button/WButton.tsx";
import { useConfig } from "../../config.ts";
import { mcn, type BaseProps } from "../../utils/baseProps";
import { cn } from "../../utils/classNames.ts";

// NB: Lazily load all of the child components to reduce initial bundle size (of which `TopNav` is a part).
const ImpersonateTopItem = React.lazy(() => import("../user/ImpersonateTopItem.tsx"));
const PaymentTopItem = React.lazy(() => import("../billing/PaymentTopItem.tsx"));
const NotificationMenu = React.lazy(() => import("../notifications/NotificationMenu.tsx"));
const UserMenu = React.lazy(() => import("../user/UserMenu.tsx"));
const DebugButton = React.lazy(() => import("./debug/DebugButton.tsx"));
const DebugBar = React.lazy(() => import("./debug/DebugMenu.tsx"));
const ColorSchemeMenu = React.lazy(() => import("./ColorSchemeMenu.tsx"));
const Hamburger = React.lazy(() => import("./mobile/Hamburger.tsx"));

const hasDebugBar = !import.meta.env.PROD;

/** Top navigation bar for the entire page */
export const TopNav = memo(function TopNav(props: BaseProps) {
    const header = (
        <header {...mcn(cn("HStack", C.TopNav), props)}>
            <TopLogo />
            <Spacer />
            <IsolatedChildren>
                <ImpersonateTopItem />
                <PaymentTopItem />
                <NotificationMenu />
                <SupportLink />
                <ColorSchemeMenu />
                {/* <LocaleButton /> */}
                {hasDebugBar && <DebugButton />}
                <UserMenu />
            </IsolatedChildren>
        </header>
    );

    return (
        <>
            {header}
            {hasDebugBar && (
                <Suspense>
                    <DebugBar />
                </Suspense>
            )}
        </>
    );
});

export const MobileTopNav = memo(function MobileTopNav(props: BaseProps) {
    return (
        <header {...mcn(`HStack ${C.TopNav} ${C.Mobile}`, props)}>
            <Suspense>
                <Hamburger />
            </Suspense>
            <TopLogo />
            <Spacer />
            <IsolatedChildren>
                <NotificationMenu />
                <ColorSchemeMenu />
                <UserMenu />
            </IsolatedChildren>
        </header>
    );
});

const TopLogo = () => <Link to="/" aria-label="Home" className={`bg-icon ${C.logo}`} />;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LocaleButton = () => (
    <WButton color="primary" variant="ghost" size="xs" icon="jp-web-icon" ariaLabel="Language" action={undefined} />
);

function SupportLink() {
    const { supportUrl } = useConfig();
    return (
        !!supportUrl && (
            <WButton
                color="primary"
                variant="ghost"
                size="bar"
                ariaLabel="Support"
                action={{ href: supportUrl, target: "_blank" }}
                icon="jp-icon-question-mark"
            >
                <span className="hidden-mobile">Support</span>
            </WButton>
        )
    );
}
