import ContentPane from "../components/ContentPane.tsx";
import { TODO } from "../dev/Todo.tsx";

export function DashboardView() {
    return (
        <ContentPane>
            <h1 className="font-size-subtitle pb-4">Dashboard</h1>

            <TODO />
        </ContentPane>
    );
}
