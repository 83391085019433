import type { QueryClient } from "@tanstack/query-core";
import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type {
    ManagedService,
    ServiceRebuildBody,
    ServiceSecrets,
    ServiceUpdateBody,
    ServiceWhiteList,
    ServiceWhitelistEntryBody,
} from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries } from "@warrenio/utils/collections/maps";
import { parallelMap } from "@warrenio/utils/collections/parallelMap";
import { assertNotNull } from "@warrenio/utils/notNull";
import { MINUTES } from "@warrenio/utils/timeUnits";
import { link } from "../../components/Action.tsx";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { optimisticPromise } from "../../utils/query/optimisticPromise.ts";
import { produceQueryData } from "../../utils/query/produceQueryData.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions, runMutation } from "../../utils/query/runMutation.ts";
import { type ApiClient, getResponseData } from "../api/apiClient.ts";
import { getResourceIconClass } from "../api/resourceTypes.tsx";
import { getQueryKey as getIpQueryKey } from "../network/ipAddress/apiOperations.ts";
import {
    dismissToast,
    raiseProgressToast,
    raiseRequestToast,
    raiseRetryErrorToast,
    type Toast,
} from "../notifications/toast.tsx";
import { serviceLink } from "./links.ts";
import type { ServiceCreateMutation } from "./service.store.ts";

export interface PackageParams {
    uuid: string;
}

export type ManagedServiceLoc = ManagedService & {
    $type: "managed_service";
    location: string;
};

/** Map of {@link ManagedService}s keyed by ID */
export type Response = Map<ManagedService["uuid"], ManagedServiceLoc>;

export const emptyResponse: Response = new Map();

const toastOptions: Partial<Toast> = { icon: getResourceIconClass("managed_service") };

export function getQueryKey(): QueryKey {
    return ["/service/packages"];
}

export function getSecretQueryKey(params?: PackageParams): QueryKey {
    return params == null ? ["/service/package/{uuid}/secrets"] : ["/service/package/{uuid}/secrets", params.uuid];
}

export function getWhitelistQueryKey(params?: PackageParams): QueryKey {
    return params == null
        ? ["/service/package/{uuid}/whitelist_addresses"]
        : ["/service/package/{uuid}/whitelist_addresses", params.uuid];
}

function serviceFromData(data: ManagedService): ManagedServiceLoc {
    return { ...data, $type: "managed_service", location: data.properties.location };
}

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey: getQueryKey(),
        queryFn: async ({ signal }): Promise<Response> => {
            return mapFromEntries(
                getResponseData(
                    await client.GET("/user-resource/service/packages", {
                        signal,
                    }),
                ),
                (service) => [service.uuid, serviceFromData(service)] as const,
            );
        },
    });
}

export const allServicesQueryAtom = atomFromStandardQueryOptions(getQuery);

export function getServiceSecretsQuery(apiClient: ApiClient, params: PackageParams) {
    return queryOptions({
        queryKey: getSecretQueryKey(params),
        queryFn: async ({ signal }): Promise<ServiceSecrets> => {
            const { uuid } = params;
            return getResponseData(
                await apiClient.GET("/user-resource/service/package/{uuid}/secrets", {
                    signal,
                    params: { path: { uuid } },
                }),
            );
        },
    });
}

//#region Service Whitelist

export function getServiceWhitelistQuery(apiClient: ApiClient, params: PackageParams) {
    return queryOptions({
        queryKey: getWhitelistQueryKey(params),
        queryFn: async ({ signal }): Promise<ServiceWhiteList> => {
            const { uuid } = params;
            return getResponseData(
                await apiClient.GET("/user-resource/service/package/{uuid}/whitelist_addresses", {
                    signal,
                    params: { path: { uuid } },
                }),
            );
        },
    });
}

export function addServiceWhitelistAddressMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: getWhitelistQueryKey(),
        async mutationFn({ body, uuid }: { body: ServiceWhitelistEntryBody; uuid: string }) {
            return getResponseData(
                await apiClient.POST("/user-resource/service/package/{uuid}/whitelist_addresses", {
                    ...jsonEncodedBody,
                    params: { path: { uuid } },
                    body,
                }),
            );
        },
        async onSettled(_res, err, _mutation) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Whitelist address added",
                error: "Error adding whitelist address",
            });
            await queryClient.invalidateQueries({ queryKey: getWhitelistQueryKey() });
        },
    });
}

export function deleteServiceWhitelistAddressMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: getWhitelistQueryKey(),
        async mutationFn({ body, uuid }: { body: ServiceWhitelistEntryBody; uuid: string }) {
            return getResponseData(
                await apiClient.DELETE("/user-resource/service/package/{uuid}/whitelist_addresses", {
                    ...jsonEncodedBody,
                    params: { path: { uuid } },
                    body,
                }),
            );
        },
        async onSettled(_res, err, _mutation) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Whitelist address deleted",
                error: "Error deleting whitelist address",
            });
            await queryClient.invalidateQueries({ queryKey: getWhitelistQueryKey() });
        },
    });
}

//#endregion Service Whitelist

export function createServiceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["managed_service/create"],
        async mutationFn({ body }: ServiceCreateMutation) {
            return serviceFromData(
                getResponseData(
                    await api.POST("/user-resource/service/package", {
                        ...jsonEncodedBody,
                        body,
                    }),
                ),
            );
        },
        onMutate(mutation) {
            raiseServiceProgressToast(mutation.requestUuid, `Creating Service [${mutation.body.display_name}]`);
            return { queryKey: getQueryKey() };
        },
        onSuccess(response, _mutation, { queryKey }) {
            produceQueryData(queryClient, queryKey, emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onError(_error, mutation, _context) {
            raiseRetryErrorToast({
                ...toastOptions,
                message: "Error creating VM",
                retryLink: link({
                    to: "/services/create",
                    state: { serviceCreateMutation: mutation },
                }),
            });
        },
        onSettled(res, err, mutation, context) {
            dismissToast(mutation.requestUuid);
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Service created",
                successType: "success",
                error: "Error creating Service",
                viewLink: res ? serviceLink(res) : undefined,
            });

            assertNotNull(context);
            const { queryKey } = context;
            optimisticPromise(queryClient.invalidateQueries({ queryKey }));
        },
    });
}

export function deleteServiceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["managed_service/delete"],
        async mutationFn({ uuid }: { uuid: string }) {
            return getResponseData(
                await api.DELETE("/user-resource/service/package/{uuid}", {
                    ...jsonEncodedBody,
                    params: { path: { uuid } },
                }),
            );
        },
        async onSettled(_res, err, _mutation) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Service deleted",
                error: "Error deleting Service",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey() });
            await queryClient.invalidateQueries({ queryKey: getIpQueryKey() });
        },
    });
}

export function updateServiceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["managed_service/update"],
        async mutationFn({ body, uuid }: { body: ServiceUpdateBody; uuid: string }) {
            return getResponseData(
                await api.PATCH("/user-resource/service/package/{uuid}", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { uuid } },
                }),
            );
        },
        async onSettled(_res, err, _mutation) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Service updated",
                error: "Error updating Service",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey() });
        },
    });
}

function startServiceResourceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["managed_service/start"],
        async mutationFn({ uuid, target_uuid }: { uuid: string; target_uuid: string }) {
            return getResponseData(
                await api.POST("/user-resource/service/package/{uuid}/resources/{target_uuid}/start", {
                    params: { path: { uuid, target_uuid } },
                }),
            );
        },
        async onSettled(_res, err, _mutation) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Starting Service VM done",
                error: "Error starting Service VM",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey() });
        },
    });
}

function stopServiceResourceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["managed_service/stop"],
        async mutationFn({ uuid, target_uuid }: { uuid: string; target_uuid: string }) {
            return getResponseData(
                await api.POST("/user-resource/service/package/{uuid}/resources/{target_uuid}/stop", {
                    params: { path: { uuid, target_uuid } },
                }),
            );
        },
        async onSettled(_res, err, _mutation) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Stopping Service VM done",
                error: "Error stopping Service VM",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey() });
        },
    });
}

export function startServiceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        async mutationFn({ body }: { body: ManagedServiceLoc }) {
            const { uuid, resources } = body;
            const vms = resources.filter((r) => r.resource_type === "vm");
            return await parallelMap(vms, async (vm) => {
                return await runMutation(queryClient, startServiceResourceMutation(api, queryClient), {
                    uuid,
                    target_uuid: vm.resource_id,
                });
            });
        },
    });
}

export function stopServiceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        async mutationFn({ body }: { body: ManagedServiceLoc }) {
            const { uuid, resources } = body;
            const vms = resources.filter((r) => r.resource_type === "vm");
            return await parallelMap(vms, async (vm) => {
                return await runMutation(queryClient, stopServiceResourceMutation(api, queryClient), {
                    uuid,
                    target_uuid: vm.resource_id,
                });
            });
        },
    });
}

export function rebuildServiceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        async mutationFn({ body, location }: { location: string; body: ServiceRebuildBody }) {
            return getResponseData(
                await api.POST("/{location}/user-resource/service/package/vm/rebuild", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Restoring Service backup done",
                error: "Error restoring Service backup",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey() });
        },
    });
}

//#region toasts

/** PostgreSQL might take a long time */
export const SERVICE_CREATE_TIMER = 5 * MINUTES;

function raiseServiceProgressToast(toastId: string, message: string) {
    raiseProgressToast({
        ...toastOptions,
        label: message,
        lifeTime: SERVICE_CREATE_TIMER,
        toastId,
    });
}

//endregion toasts
