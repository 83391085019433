import C from "../MainLayout.module.css";

import { CatchBoundary } from "@tanstack/react-router";
import { clamp } from "@warrenio/utils/clamp";
import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useAtomValue } from "jotai/react";
import { Suspense, memo, useEffect, useMemo, useRef, useState, type ReactNode } from "react";
import { useMove } from "react-aria";
import { SkeletonSidebar } from "../../../components/sidebar/StandardSidebar";
import { mcn, type BaseProps } from "../../../utils/baseProps";
import { cleanTimeout } from "../../../utils/cleanTimeout.ts";
import { isBuiltRelease } from "../../../utils/environment.ts";
import { currentSectionAtom, sectionsAtom, sidebarComponentAtom } from "./Sidebar.store";

export const Sidebar = memo(function Sidebar(props: BaseProps) {
    const Component = useAtomValue(sidebarComponentAtom);
    const section = useAtomValue(currentSectionAtom);

    // Keep the inner component memoized for efficiency (probably not necessary)
    const component = useMemo(() => {
        if (!Component) return null;

        // Add the standard wrapper components - Suspense and error boundary
        return (
            <CatchBoundary getResetKey={() => section ?? ""}>
                <Suspense fallback={<SkeletonSidebar />}>
                    <Component />
                </Suspense>
            </CatchBoundary>
        );
    }, [section, Component]);

    usePreloadSidebar();

    return component ? <Resizable {...props}>{component}</Resizable> : null;
});

const PRELOAD_DELAY = 2000;

function usePreloadSidebar() {
    const sections = useAtomValue(sectionsAtom);
    useEffect(() => {
        // No preload in development mode
        if (!isBuiltRelease) {
            return;
        }
        return cleanTimeout(() => {
            const preloadPromises = filterFalse(
                sections.map((s) => s.sidebar).map((s) => s && "preload" in s && s.preload()),
            );
            return discardPromise(Promise.all(preloadPromises));
        }, PRELOAD_DELAY);
    }, [sections]);
}

// TODO: Better way to use localStorage then just writing/reading string from it
function Resizable({ children, ...props }: { children: ReactNode } & BaseProps) {
    const minWidth = 250;
    const maxWidth = 500;
    const defaultWidth = 330;
    const maxScreenRatio = 0.5;

    const storageKey = "sidebarWidth";

    function clampWidth(value: number = window.innerWidth) {
        const max = Math.max(maxWidth, window.innerWidth * maxScreenRatio);
        return clamp(value, minWidth, max);
    }

    const [width, setWidth] = useState(() => {
        const value = Number(localStorage.getItem(storageKey));
        if (value) {
            return clampWidth(value);
        }
        return defaultWidth;
    });

    // Does not need to be state since it's not used for rendering
    const unclampedWidth = useRef(0);

    const { moveProps } = useMove({
        onMoveStart(_event) {
            unclampedWidth.current = width;
        },
        onMove: (event) => {
            unclampedWidth.current += event.deltaX;
            const newWidth = clampWidth(Math.round(unclampedWidth.current));
            if (newWidth !== width) {
                setWidth(newWidth);
            }
        },
        onMoveEnd: (_event) => {
            localStorage.setItem(storageKey, String(width));
        },
    });

    return (
        <aside {...mcn("flex flex-row items-stretch h-full", props)}>
            <div className="h-full" style={{ width: `${width}px` }}>
                {children}
            </div>
            <div className={C.resizer} {...moveProps} />
        </aside>
    );
}
