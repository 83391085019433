import C from "./StandardSidebar.module.css";

import { Link } from "@tanstack/react-router";
import type { ReactNode } from "react";
import { mcn, type BaseProps } from "../../utils/baseProps";
import { cn } from "../../utils/classNames.ts";
import type { RouterLinkProps } from "../Action.tsx";
import { Spacer } from "../Spacer";
import { SkeletonFixed } from "../loading/SkeletonFixed";
import { SkeletonListItem } from "./SkeletonListItem.tsx";

export interface SidebarHeaderProps {
    children?: ReactNode;
    title: ReactNode;
    sortMenu?: ReactNode;
    searchField?: ReactNode;

    queryString?: string;
    onQueryStringChange?: (searchKeyword: string) => void;
}

export function SidebarHeader({ children, title, searchField, sortMenu }: SidebarHeaderProps) {
    return (
        <div className={C.SidebarHeader}>
            <div className="HStack p-2">
                <b className="text-ellipsis whitespace-nowrap">{title}</b>
                <Spacer />
                {children}
            </div>

            {searchField && <div className={cn("p-2", C.SidebarSearch)}>{searchField}</div>}

            {sortMenu && <div className={cn("HStack p-2 pt-0", C.SidebarOrdering)}>{sortMenu}</div>}
        </div>
    );
}

/*
XXX: Had to comment out as it would be needed in some format for mobile and multi level views

export function CloseSidebarButton(props: PropsWithChildren<BaseProps>) {
    const closeSidebar = useSetAtom(closeSidebarAction);
    return (
        <WButton
            variant="border"
            size="bar"
            onPress={() => {
                closeSidebar();
                return false;
            }}
            fontSize="font-size-default"
            ariaLabel="Close sidebar"
            // Example: Use a custom component as icon with a different (relative) size
            icon={<MaskIcon className="jp-icon-caretleft size-80%" />}
            {...props}
        />
    );
}
*/

export function ListBlock({ children, ...props }: { children?: ReactNode } & BaseProps) {
    return (
        <div className={C.SidebarList} {...props}>
            {children}
        </div>
    );
}

export function StandardBlock({
    children,
    link,
    ...props
}: { children?: ReactNode; link?: RouterLinkProps } & BaseProps) {
    const inner = <div {...mcn(C.StandardBlock, props)}>{children}</div>;
    return link ? (
        <Link className={C.StandardBlockLink} {...link}>
            {inner}
        </Link>
    ) : (
        inner
    );
}

//#region Skeletons
export function SkeletonBlock(props: BaseProps) {
    return (
        <StandardBlock className={cn(C.StandardBlock, C.skeleton)} {...props}>
            <SkeletonListItem />
        </StandardBlock>
    );
}

export function SkeletonSidebarHeader(props: BaseProps) {
    return <SidebarHeader title={<SkeletonFixed ems={8} />} {...props} />;
}

export function SkeletonSidebar(props: BaseProps) {
    return (
        <>
            <SkeletonSidebarHeader {...props} />
            <SkeletonBlock />
        </>
    );
}

//#endregion
