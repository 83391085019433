import C from "./AdminLayout.module.css";

import type { ReactNode } from "react";
import { cn } from "../utils/classNames.ts";

export function AdminTitle({ title, children }: { title: string; children?: ReactNode }) {
    return (
        <div className={cn(C.Title, "flex items-center justify-between p-2")}>
            <h1 className="font-size-default text-ellipsis whitespace-nowrap font-bold">{title}</h1>
            {children}
        </div>
    );
}
