import type { DefaultError, QueryClient, QueryKey } from "@tanstack/query-core";
import type { UseMutationOptions } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { type ApiClient, getResponseData, type TypedFetchResponse } from "../../modules/api/apiClient.ts";
import { raiseRequestToast } from "../../modules/notifications/toast.tsx";

interface AdminMutationOptions<TData, TVariables> extends UseMutationOptions<TData, DefaultError, TVariables, unknown> {
    /**
     * Optional function that makes the request (e.g. `apiClient.GET`).
     *
     * Only use when no further processing of the response is needed.
     * Otherwise, use {@link mutationFn}.
     */
    requestFn?: (variables: TVariables) => Promise<TypedFetchResponse<TData>>;
    /** Get query key to invalidate */
    invalidateQueryKey?: ((variables: TVariables) => QueryKey) | QueryKey;

    /** Entity name for toast */
    entity: string;
    /** Operation name for toast */
    operation: string;
}

/**
 * Utility function for admin mutations.
 *
 * Automatically shows a toast and invalidates a query key.
 */
export function adminMutation<TData, TVariables>(
    fn: (apiClient: ApiClient, queryClient: QueryClient) => AdminMutationOptions<TData, TVariables>,
) {
    return (
        apiClient: ApiClient,
        queryClient: QueryClient,
    ): UseMutationOptions<TData, DefaultError, TVariables, unknown> => {
        const { invalidateQueryKey, entity, operation, requestFn, ...base } = fn(apiClient, queryClient);

        invariant(!requestFn || !base.mutationFn, "Cannot provide both requestFn and mutationFn");

        return {
            mutationKey: [`${entity}/${operation}`],
            mutationFn: requestFn
                ? async (variables) => {
                      return getResponseData(await requestFn(variables)) satisfies TData;
                  }
                : undefined,

            ...base,

            async onSettled(_data, err, variables, _context) {
                raiseRequestToast(err, {
                    success: `${entity} ${operation}: Success`,
                    error: `${entity} ${operation}: Error`,
                });

                if (invalidateQueryKey) {
                    const queryKey =
                        typeof invalidateQueryKey === "function" ? invalidateQueryKey(variables) : invalidateQueryKey;
                    await queryClient.invalidateQueries({ queryKey });
                }

                await base.onSettled?.(_data, err, variables, _context);
            },
        };
    };
}
