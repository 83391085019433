/* eslint-disable prefer-rest-params */

/** Trace every function/method call on an object in the console.
 *
 * Example console log: `prefix.fieldName( "arg1", 123, { obj: "value" } )`
 */
export function addFunctionTracers<T extends object>(obj: T, prefix: string): T {
    if (!import.meta.env.DEV) {
        return obj;
    }

    return new Proxy(obj, {
        get(target: T, prop, receiver: unknown) {
            const value = Reflect.get(target, prop, receiver);
            if (typeof value !== "function") {
                return value;
            }

            return function (this: unknown): unknown {
                const argPlaceholders = Array(arguments.length).fill("%o").join(", ");
                const key = String(prop);

                console.debug(`${prefix}.${key}( ${argPlaceholders} )`, ...arguments);

                return value.apply(this, arguments);
            };
        },
    });
}
