import { Children, Suspense, type ReactNode, type SuspenseProps } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { InfoTooltip } from "./WTooltip.tsx";

interface IsolatedProps {
    fallback?: SuspenseProps["fallback"];
}

/** Wrapper component to isolate components from each other - contains an error boundary and suspense */

export function Isolated({ children, fallback }: { children: ReactNode } & IsolatedProps) {
    return (
        <ErrorBoundary
            fallbackRender={({ error }: { error: unknown }) => (
                <InfoTooltip text={String(error)}>
                    <span className="text-error">Error</span>
                </InfoTooltip>
            )}
        >
            <Suspense fallback={fallback}>{children}</Suspense>
        </ErrorBoundary>
    );
}

export function IsolatedChildren({ children, ...props }: { children: ReactNode[] } & IsolatedProps) {
    return Children.map(children, (child) => <Isolated {...props}>{child}</Isolated>);
}
