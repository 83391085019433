import type { MetalStatus } from "@warrenio/api-spec/spec.oats.gen";
import { mustGetProperty } from "@warrenio/utils/collections/getOwnProperty";
import type { ReactNode } from "react";
import { entries } from "remeda";
import type { BadgeProps } from "../../../components/Badge.tsx";

interface MetalStatusInfo {
    color: BadgeProps["color"];
    title: string;
    description: ReactNode;
}

export const statusLabels: Record<MetalStatus, MetalStatusInfo> = {
    available: {
        color: "success",
        title: "Available",
        description: "Machine is available for immediate leasing by clients.",
    },
    pending: {
        color: "warning",
        title: "Pending",
        description: (
            <>
                Machine is ordered by client, but does not satisfy the required configuration.
                <br />
                After it has been configured as needed, move to “in use” status.
            </>
        ),
    },
    in_use: {
        color: "primary",
        title: "In Use",
        description: "Machine is being used by client and is being charged .",
    },
    cleaning: { color: "default", title: "Cleaning", description: "Machine requires disk cleaning operations." },
    offline: { color: "default", title: "Offline", description: "Machine is unavailable or requires repairs." },
    deleted: { color: "error", title: "Deleted", description: "" },
};

export interface MetalStatusItem extends MetalStatusInfo {
    id: MetalStatus;
}

export const statusItems = entries(statusLabels).map(([id, obj]): MetalStatusItem => ({ id, ...obj }));

export function getMetalStatusInfo(value: MetalStatus): MetalStatusInfo {
    return mustGetProperty(statusLabels, value);
}
