import type { DefaultError, QueryClient } from "@tanstack/query-core";
import { MutationObserver } from "@tanstack/query-core";
import type { UseMutationOptions } from "@tanstack/react-query";
import { addFunctionTracers } from "../debug/addFunctionTracers";

/** Run a mutation outside of a React context. Useful for eg. nesting mutations. */
export async function runMutation<TData = unknown, TError = DefaultError, TVariables = void, TContext = unknown>(
    queryClient: QueryClient,
    options: UseMutationOptions<TData, TError, TVariables, TContext>,
    variables: TVariables,
) {
    const observer = new MutationObserver<TData, TError, TVariables, TContext>(queryClient, options);
    return await observer.mutate(variables, options);
}

/** Identity function to create strongly typed {@link UseMutationOptions} */
export function mutationOptions<TData = unknown, TError = DefaultError, TVariables = void, TContext = unknown>(
    options: UseMutationOptions<TData, TError, TVariables, TContext>,
) {
    const { mutationKey } = options;
    if (import.meta.env.DEV && mutationKey) {
        return addFunctionTracers(options, mutationKey.join(", "));
    }

    return options;
}
