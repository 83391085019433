import { createFileRoute } from "@tanstack/react-router";
import { useAtomValue } from "jotai/react";
import { AdminLayout } from "../admin/AdminLayout.tsx";
import { link } from "../components/Action.tsx";
import { WButton } from "../components/button/WButton.tsx";
import { BaseRouterRoot } from "../modules/main/BaseRouterRoot.tsx";
import { userIsAdminAtom } from "../modules/user/apiOperations.ts";

export const Route = createFileRoute("/admin")({
    component: AdminComponent,
});

function AdminComponent() {
    const isAdmin = useAtomValue(userIsAdminAtom);
    if (!isAdmin) {
        return (
            <div className="VStack items-center gap-2">
                <div>Only for administrative users</div>
                <WButton action={link({ to: "/" })}>Back to main app</WButton>
            </div>
        );
    }

    return <BaseRouterRoot Layout={AdminLayout} />;
}
