import C from "./ActionMenu.module.css";

import { notNull } from "@warrenio/utils/notNull";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import type { PropsWithChildren, ReactNode } from "react";
import {
    Header,
    Menu,
    MenuItem,
    MenuTrigger,
    Popover,
    SubmenuTrigger,
    type Key,
    type PopoverProps,
    type SelectionMode,
} from "react-aria-components";
import { TODO } from "../dev/Todo.tsx";
import { isTodoFn } from "../dev/todoFn.ts";
import { MaskIcon } from "./icon/MaskIcon.tsx";

export interface ActionMenuItemProps {
    id: Key;

    title: ReactNode;
    /** Required for accessibility when {@link title} is not a string. */
    textValue?: string;

    icon?: string;
    image?: ReactNode;
    isDisabled?: boolean;

    action?: () => void | Promise<void>;
    childItems?: ActionMenuItemProps[];
}

export interface ActionMenuContentProps<T extends ActionMenuItemProps> {
    header?: ReactNode;

    placement?: PopoverProps["placement"];

    items: T[];
    onAction?: (item: T) => void;
    selectedKeys?: Key[];
    selectionMode?: SelectionMode;
}

export function ActionMenuItem({
    id,
    title,
    textValue,
    image,
    icon,
    isDisabled,
    isChecked = false,
    action,
}: ActionMenuItemProps & { isChecked?: boolean }) {
    return (
        <MenuItem
            id={id}
            className={C.ActionMenuItem}
            isDisabled={isDisabled}
            textValue={textValue ?? (typeof title === "string" ? title : undefined)}
        >
            {(icon != null || image != null) && (
                <div className={C.ItemIcon}>
                    {icon != null && <MaskIcon className={icon} />}
                    {image}
                </div>
            )}
            <div className={C.title}>
                {title}
                {isTodoFn(action) && <TODO small />}
            </div>
            {isChecked && <MaskIcon className="jp-icon-checkmark color-success size-1rem" />}
        </MenuItem>
    );
}

export function ActionMenuContent<T extends ActionMenuItemProps>({
    header,
    items,
    onAction,
    selectedKeys,
    selectionMode = "single",
    placement,
}: ActionMenuContentProps<T>) {
    onAction ??= (item) => {
        console.debug("ActionMenu item clicked: %o", item);
        discardPromise(item.action?.());
    };

    function makeMenuItem(item: ActionMenuItemProps) {
        const { id, childItems } = item;
        if (childItems) {
            return (
                <SubmenuTrigger key={id}>
                    <ActionMenuItem {...item} />
                    <WPopover offset={8}>{makeMenu(childItems as T[])}</WPopover>
                </SubmenuTrigger>
            );
        }
        return <ActionMenuItem key={id} {...item} isChecked={selectedKeys?.includes(id)} />;
    }

    function makeMenu(items: T[]) {
        const menuItems = items.map(makeMenuItem);
        return (
            <Menu
                onAction={(key) => {
                    const item = notNull(items.find((item) => item.id === key));
                    onAction!(item);
                }}
                selectedKeys={selectedKeys}
                selectionMode={selectionMode}
                className={C.Scrollable}
            >
                {menuItems}
            </Menu>
        );
    }

    return (
        <WPopover placement={placement}>
            {!!header && <Header className={C.ActionMenuHeader}>{header}</Header>}
            {makeMenu(items)}
        </WPopover>
    );
}

export function WPopover(props: PopoverProps) {
    // Tweak offset to prevent gap between button and popover.
    return <Popover className={C.ActionMenu} offset={2} {...props} />;
}

export function ActionMenu<T extends ActionMenuItemProps>({
    children,
    ...props
}: PropsWithChildren<ActionMenuContentProps<T>>) {
    return (
        <MenuTrigger>
            {children}
            <ActionMenuContent {...props} />
        </MenuTrigger>
    );
}
