import type { ReplicaType, VirtualMachineStorageReplica } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import type { VirtualMachineLoc } from "./vmQuery.ts";

export function getPrimaryStorage<T extends { primary: boolean }>(vm: { storage: T[] }): T {
    return notNull(
        vm.storage.find((item) => item.primary),
        "VM primary storage",
    );
}

export function getReplicasByType(vm: VirtualMachineLoc, type: ReplicaType) {
    const primaryStorage = getPrimaryStorage(vm);
    return primaryStorage.replica?.filter((item: VirtualMachineStorageReplica) => item.type === type) ?? [];
}

export function getReplicaByUuid(vm: VirtualMachineLoc, replicaUuid: string) {
    const primaryStorage = getPrimaryStorage(vm);
    return primaryStorage.replica?.find((item: VirtualMachineStorageReplica) => item.uuid === replicaUuid);
}
