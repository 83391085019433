import { discardPromise } from "@warrenio/utils/promise/discardPromise";

/**
 * Marker for query invalidations that can run in the background, because their results have already optimistically been
 * added to the existing query data.
 *
 * NB: __Do not use without corresponding optimistic mutations!__
 */
export function optimisticPromise(promise: Promise<void>) {
    discardPromise(promise);
}
