import { objectFromKeys } from "@warrenio/utils/collections/objs";
import { atom, type Atom } from "jotai/vanilla";
import { mergeQueries, type QMergedResult, type QResult } from "../../utils/query/mergeQueries.ts";
import { locationsAtom } from "../api/locations.store.ts";

/** Create an atom that queries all locations and merges the results using {@link mergeDataFn}. */
export function atomAllLocationsQuery<T, TMerged>(
    locationQueryAtomFn: (location: string) => Atom<QResult<T>>,
    mergeDataFn: (data: T[]) => TMerged,
): Atom<QMergedResult<TMerged>> {
    return atom((get) => {
        const locations = get(locationsAtom);
        const results = objectFromKeys(locations, (location) => get(locationQueryAtomFn(location)));
        return mergeQueries(results, mergeDataFn);
    });
}
