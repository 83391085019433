import { useState } from "react";
import { WSearchField } from "../components/forms/WSearchField.tsx";
import { WTable, WTableBody } from "../components/table/WTable.tsx";
import { AdminTitle } from "./AdminTitle.tsx";

export function ReportsView() {
    const [queryString, setQueryString] = useState("");

    return (
        <>
            <AdminTitle title="Reports" />

            <WTable adminTable search={<WSearchField value={queryString} onChange={setQueryString} />}>
                <thead>
                    <tr>
                        <th />
                        <th>User ID</th>
                        <th>BA ID</th>
                        <th>Billing Account</th>
                        <th>Created At</th>
                        <th>Report ID</th>
                        <th>Report Type</th>
                        <th>Period Start</th>
                        <th>Period End</th>
                        <th>Period</th>
                        <th>Resource Usage Subtotal</th>
                        <th>Subtotal</th>
                        <th>Discount</th>
                        <th>Discount %</th>
                        <th>Credit</th>
                        <th>VAT</th>
                        <th>Total</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <WTableBody>
                    <tr />
                </WTableBody>
            </WTable>
        </>
    );
}
