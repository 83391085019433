import { useRef } from "react";
import { useLayoutSizeObserver } from "../../utils/useLayoutSizeObserver.ts";

const breakPoints: [number, string][] = [
    [1640, "xl"],
    [1280, "lg"],
    [1024, "md"],
    [768, "sm"],
    [520, "xs"],
    [0, "xxs"],
];
const defaultBreakpoint = "md";

/** Add a `data-screen` attribute to with the current breakpoint size to an element.
 * @returns A ref that should be attached to the element to observe the size of.
 */
export function useBreakpointSizeAttribute(debugPerformance = false) {
    const ref = useRef<HTMLElement>(null);
    useLayoutSizeObserver({
        ref,
        onResize(rect, el) {
            const { width } = rect;
            const breakpoint = breakPoints.find(([bw]) => width >= bw);
            const size = breakpoint?.[1] ?? defaultBreakpoint;

            // Mutate the DOM directly so the attribute is available immediately on first paint
            if (el.getAttribute("data-screen") !== size) {
                if (import.meta.env.DEV && debugPerformance) {
                    console.debug("Set breakpoint: %s (%o) on %o", size, rect, ref.current);
                    performance.mark(`Set breakpoint: ${size}`);
                }

                el.setAttribute("data-screen", size);
            }
        },
    });
    return ref;
}
