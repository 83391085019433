import { createFileRoute } from "@tanstack/react-router";
import { BaseRouterRoot } from "../modules/main/BaseRouterRoot.tsx";
import { MobileLayoutSwitch } from "../modules/main/mobile/MobileLayoutSwitch.tsx";

export const Route = createFileRoute("/_main")({
    component: MainComponent,
});

function MainComponent() {
    return <BaseRouterRoot Layout={MobileLayoutSwitch} />;
}
