/** A generic reference to an assignable resource */
export interface ResourceId<T extends string> {
    $type: T;
    $id: string;
    location: string;
}

/** Placeholder for a resource that has been assigned but is not in any list query */
export const UNKNOWN_RESOURCE = Symbol.for("resources/UNKNOWN_RESOURCE");
export type UNKNOWN_RESOURCE = typeof UNKNOWN_RESOURCE;
