import { QueryClient } from "@tanstack/query-core";
import { MINUTES, SECONDS } from "@warrenio/utils/timeUnits";
import { atom } from "jotai/vanilla";
import { addDevVars } from "../../dev/initDev.tsx";
import { ApiConnectionError, ApiUnknownError } from "./ApiError.ts";
import { currentApiKeyAtom } from "./apikey.store";

export const queryClientAtom = atom((get) => {
    // Trigger the apikeyAtom to be watched, so we reinitialize the client when it changes (to clear the cache)
    get(currentApiKeyAtom);
    return createClient();
});

export function createClient() {
    console.debug("Create new QueryClient");
    return new QueryClient({
        defaultOptions: {
            queries: {
                retry: (attemptIndex, error) => {
                    // Max 3 retries
                    if (attemptIndex >= 3) {
                        return false;
                    }
                    if (error instanceof ApiConnectionError) {
                        console.info("Connection error retry attempt %s:", attemptIndex, error);
                        return true;
                    }
                    if (error instanceof ApiUnknownError) {
                        console.info("API error retry attempt %s:", attemptIndex, error);
                        // 502 Bad Gateway, backend is probably restarting
                        return error.meta.status === 502;
                    }
                    return false;
                },
                retryDelay: 7 * SECONDS,

                gcTime: 10 * MINUTES,
                staleTime: 60 * SECONDS,
                // TODO: support `structuralSharing` of `Map` and `Set`
            },
        },
    });
}

addDevVars({ queryClientAtom });
