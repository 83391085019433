import type { QueryClient } from "@tanstack/query-core";
import type { QueryKey } from "@tanstack/react-query";
import { produce } from "../immer.ts";

/** Optimistically mutate the query data with the given key. (Uses Immer to make the mutation easier.)
 *
 * @param empty - The initial data to use when the query is not in the cache.
 * @param producer - A function that mutates the data. It receives the old data and should mutate it in place.
 */
export function produceQueryData<TData>(
    queryClient: QueryClient,
    queryKey: QueryKey,
    empty: TData,
    // biome-ignore lint/suspicious/noConfusingVoidType: Biome is incorrect here, using `undefined` breaks.
    producer: (old: TData) => TData | void,
) {
    return queryClient.setQueryData<TData>(queryKey, (old = empty) => produce(old, producer));
}
