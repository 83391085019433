import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type { AdminMetalHistoryItem, MetalMachine } from "@warrenio/api-spec/spec.oats.gen";
import { type ApiClient, getResponseData } from "../../../modules/api/apiClient.ts";

//#region Response
export interface AdminMetalHistoryItemLoc extends AdminMetalHistoryItem {
    $type: "metal_machine_history";
    location: string;
}

function metalFromData(data: AdminMetalHistoryItem, location: string): AdminMetalHistoryItemLoc {
    return { ...data, $type: "metal_machine_history", location };
}
//#endregion

//#region Query and parameters
export interface Params {
    location: string;
    uuid: MetalMachine["uuid"];
}

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["metal_machine/history"] : ["metal_machine/list", params.location, params.uuid];
}

export function getQuery(client: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<AdminMetalHistoryItemLoc[]> => {
            const { location, uuid } = params;
            return getResponseData(
                await client.GET("/{location}/admin/metal/machines/{uuid}/history", {
                    signal,
                    params: { path: { location, uuid } },
                }),
            ).map((metal) => metalFromData(metal, location));
        },
    });
}

//#endregion
