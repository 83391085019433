export async function parallelMap<T, TResult>(
    items: T[],
    fn: (item: T, index: number, items: T[]) => Promise<TResult>,
): Promise<PromiseSettledResult<Awaited<TResult>>[]> {
    return await Promise.allSettled(
        items.map(
            // Intentionally wrap in an `async` function to ensure synchronous exceptions are returned as a rejected promise
            async (item, index, items) => await fn(item, index, items),
        ),
    );
}
