import C from "./Toolbar.module.css";

import { useToolbar, type AriaToolbarProps } from "@react-aria/toolbar";
import React, { useEffect, useRef, useState, type PropsWithChildren, type ReactNode } from "react";
import { mcn } from "../utils/baseProps.ts";
import { useLayoutSizeObserver } from "../utils/useLayoutSizeObserver.ts";
import { WButton } from "./button/WButton.tsx";

function hide(el: HTMLElement, hidden = true) {
    el.style.visibility = hidden ? "hidden" : "visible";
}

export function Toolbar(props: PropsWithChildren<AriaToolbarProps>) {
    const ref = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const [currentVisibleCount, setVisibleCount] = useState<number>(Infinity);
    const { toolbarProps } = useToolbar(props, ref);

    const items = React.Children.toArray(props.children);

    function updateVisibleItems() {
        const toolbarList = listRef.current;
        if (!toolbarList) {
            console.warn("No tab element found");
            return;
        }

        const tabs = toolbarList.children;
        const maxWidth = toolbarList.clientWidth;

        if (tabs.length === 0) {
            console.warn("No tabs found");
            return;
        }

        // Iterate through all tab elements one-by-one and stop when the right edge of the tab is beyond `maxWidth`.
        let visibleCount = 0;
        for (let i = 0; i < tabs.length; i++) {
            const tab = tabs[i] as HTMLElement;
            const endOffset = tab.offsetLeft + tab.offsetWidth;
            if (endOffset > maxWidth) {
                console.debug("Tab(%s): Does not fit into %s at offset %s", i, maxWidth, endOffset);
                hide(tab);

                // Hide remaining tabs
                for (; i < tabs.length; i++) {
                    hide(tabs[i] as HTMLElement);
                }

                break;
            } else {
                // Show previously hidden tabs (when they now fit)
                hide(tab, false);
            }

            visibleCount++;
        }

        if (visibleCount === 0) {
            console.warn("No tabs fit into %s", maxWidth);
            visibleCount = 1;
        }

        setVisibleCount((prev) => {
            if (prev !== visibleCount) {
                console.debug(
                    "Visible tabs: %s in width %s (out of %s rendered, %s existing)",
                    visibleCount,
                    maxWidth,
                    tabs.length,
                    items.length,
                );
            }
            return visibleCount;
        });
    }

    // NB: This monitors the width of the parent element, but the actual width used is the width of the inner toolbarList.
    // This way if the toolbarList changes size it does not cause an infinite loop. This is perhaps not necessary.
    useLayoutSizeObserver({
        ref,
        onResize(_rect, _el) {
            updateVisibleItems();
        },
    });

    // Re-measure the tabs when the `items` change (based on only the count currently, to speed up the re-renders)
    useEffect(() => {
        // Use a timeout to make sure new tabs have been rendered (hopefully...) since react-aria seems to not render
        // them for the initial effect. Might not work with React concurrent mode.
        setTimeout(() => {
            updateVisibleItems();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items.length]);

    const menuItems = items.slice(currentVisibleCount);

    return (
        <div aria-label="Actions" {...mcn(C.Toolbar, toolbarProps)} ref={ref}>
            <div ref={listRef} className={C.List}>
                {items}
            </div>

            <div className={C.Right}>{menuItems.length > 0 && <ToolbarNavigation items={menuItems} />}</div>
        </div>
    );
}

function ToolbarNavigation({ items }: { items: ReactNode[] }) {
    const [visible, setVisible] = useState<boolean>(false);

    function toggleVisible() {
        setVisible(!visible);
    }

    return (
        <div className={C.Menu}>
            <WButton
                color="primary"
                variant="ghost"
                size="bar"
                ariaLabel="Extra items"
                icon="jp-icon-ellipses"
                action={toggleVisible}
                className={C.Button}
            />

            {visible && <div className={C.Content}>{items}</div>}
        </div>
    );
}
