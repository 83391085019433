import { showWarn } from "./errorStream.ts";
import { todoFn } from "./todoFn";

export function todoLog(message: string) {
    const msg = `TODO: ${message}`;
    showWarn(msg);
}

export const todoSubmit = todoFn(() => {
    todoLog("implement submit handler");
});

export const todoEvent = todoFn(() => {
    todoLog("implement event handler");
});

export const todoAction = todoFn(() => {
    todoLog("implement action handler");
});
