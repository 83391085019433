import TC from "./Table.module.css";

import type { ReactNode } from "react";
import { cn } from "../../utils/classNames.ts";
import { InfoTooltip, type WTooltipProps } from "../WTooltip.tsx";

export interface DetailsTableRowProps {
    title?: ReactNode;
    tooltip?: WTooltipProps["text"];
    children?: ReactNode;
    thWide?: boolean;
}

export function DetailsTableRow({ title, tooltip, children, thWide }: DetailsTableRowProps) {
    return title ? (
        <tr>
            <th className={cn(thWide && TC.Wide)}>
                <div className="flex">
                    {title}
                    {tooltip && <InfoTooltip className="ml-1" text={tooltip} />}
                </div>
            </th>
            <td>{children}</td>
        </tr>
    ) : (
        <tr>
            <td colSpan={2}>&nbsp;</td>
        </tr>
    );
}
