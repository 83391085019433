import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { metalOsQueryAtom } from "../metalOsQuery.ts";

export function useMetalOs(os_id: string | null | undefined) {
    const data = useSuspenseQueryAtom(metalOsQueryAtom);
    const { os, os_types } = data;

    const selectedOs = os_id ? os.find((os) => os.os_id === os_id) : null;
    const osType = selectedOs ? os_types.find((osType) => osType.os_type_id === selectedOs.os_type_id) : null;

    return {
        icon: osType?.icon,
        title: osType && selectedOs ? `${osType.title} ${selectedOs.version_title}` : os_id,
    };
}
