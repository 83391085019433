import C from "./SideNav.module.css";

import { Link } from "@tanstack/react-router";
import type React from "react";
import type { ReactNode } from "react";
import { mcn, type WithBaseProps } from "../../utils/baseProps";
import { cn } from "../../utils/classNames";

export interface NavItem {
    id: string;
    label: ReactNode;
    icon: string;
    /** Link to navigate to */
    to?: string;
}

export interface SideNavProps<T extends NavItem> {
    /** ID of selected item */
    currentItemId?: string | null | undefined;
    /**
     * List of items to display.
     * Can use a custom type extending from {@link NavItem} to store extra info.
     */
    items: T[];

    onPressItem?: (tab: T, evt: React.MouseEvent) => void;
}

/** Left-hand side navigation bar with icons */
export function SideNav<T extends NavItem>({
    currentItemId,
    items,
    onPressItem,
    ...props
}: WithBaseProps<SideNavProps<T>>) {
    return (
        <nav {...mcn(C.SideNav, props)}>
            <menu className="VStack">
                {items.map((item, index) => (
                    <SideNavButton
                        key={index}
                        item={item}
                        selected={item.id === currentItemId}
                        onClick={(evt) => onPressItem?.(item, evt)}
                    />
                ))}
            </menu>
        </nav>
    );
}

function SideNavButton({
    item: { icon, label, to },
    selected = false,
    onClick,
}: {
    item: NavItem;
    selected?: boolean;
    onClick?: React.MouseEventHandler;
}) {
    // TODO: Keyboard accessibility?
    const props = {
        className: cn(C.SideNavButton, selected && C.active),
        // WORKAROUND: Tanstack Router Link has buggy event type "a"
        onClick: <T extends Element | "a">(e: React.MouseEvent<T>) => {
            onClick?.(e as React.MouseEvent);
            if (!to) {
                return false;
            }
        },
        children: (
            <>
                <div className={`mask-icon ${C.icon} ${icon}`} />
                <div className={C.label}>{label}</div>
            </>
        ),
    };
    return to ? <Link {...props} to={to} /> : <li {...props} />;
}
